import ArrowCircleRightIcon from '@mui/icons-material/ArrowCircleRight';

import { GridActionsCellItem } from '@mui/x-data-grid';
import dayjs from 'dayjs';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';
import DataBox from './DataBox';
import { AddCircleRounded, GroupRounded, RemoveCircleRounded } from '@mui/icons-material';
import { locationText } from 'utils/locationText';
import { jobOrderColNm } from 'constants/jobOrderConstant';
import { dailyExpenseColNm } from 'constants/dailyExpenseConstant';
import { numberFormat } from 'utils';
import BatteryColor from './BatteryColor';
import { BoxFC, BoxFR } from 'components/BoxCustom';
import { Box, Typography } from '@mui/material';

export const jobNoDriverColumns = (handleSelect) => [
  {
    field: 'AptTm',
    headerName: "เวลานัด",
    width: 100,
    valueGetter: (params) => params.value && dayjs(params.value),
    valueFormatter: (params) => params.value ? params.value.format("DD/MM HH:mm") : "-",
  },
  {
    field: 'CusSNm',
    headerName: 'ลูกค้า',
    renderCell: ({ row, value }) => <BoxFR sx={{gap: 0}}>
      <Box width={3} height={25} bgcolor={row.IsExpanded?"primary.main": "transparent"}/>
      {row.IsExpanded ?
        <RemoveCircleRounded fontSize="small" color="primary" /> : <AddCircleRounded fontSize="small" color="primary" />}
      {value}
    </BoxFR>
  },
  {
    field: 'JobNo',
    headerName: 'JobNo',
    flex: 1,
    renderCell: (params) => {
      //make rtl text in div with textoverflow ellipsis
      return <div style={{ direction: "rtl", overflow: "hidden", textOverflow: "ellipsis" }}>{params.value}</div>
    }
  },
  {
    field: 'ContSizeFull',
    headerName: 'ขนาดตู้',
    width: 70,
  },
  {
    field: 'Loc',
    headerName: "สถานที่",
    flex: 1,
    valueGetter: (params) => locationText(params.row),
  },
  {
    field: 'RtnDteEn',
    headerName: "Closing",
    width: 100,
    valueGetter: (params) => params.value && dayjs(params.value),
    valueFormatter: (params) => params.value ? params.value.format("DD/MM HH:mm") : "-",
  },
  {
    field: 'IsRdyTake',
    headerName: 'เช็ค',
    width: 45,
    renderCell: (params) => {
      return params.value === 1 ? <CheckCircleIcon color="success" /> : <CancelIcon color="action" />
    },
  },
  {
    field: 'Count',
    headerName: 'จำนวน',
    valueGetter: (params) => params.row.JobOrdIds.length,
    valueFormatter: (params) => params.value.toString(),
    width: 50,
    align: "center"
  },
  {
    field: "actions",
    type: "actions",
    minWidth: 0,
    hide: !handleSelect,
    width: 30,
    getActions: (params) => [
      <GridActionsCellItem
        disabled={!params.row.IsExpanded}
        icon={<ArrowCircleRightIcon color={params.row.IsExpanded ? "primary" : "disabled"} sx={{ fontSize: 25 }} />}
        label="Select"
        onClick={() => handleSelect(params.row)}
      />
    ],
  }
]

const locText = (jobOrderData) => {

  const msData = JSON.parse(localStorage.getItem("msData"))

  let loc = msData.poiObj[jobOrderData.LocPOIId] || jobOrderData.Loc
  let rtn = msData.poiObj[jobOrderData.RtnPOIId] || jobOrderData.RtnPlc

  return `${loc}${`-${rtn}`}`
}

const rtnText = (jobOrderData) => {
  const msData = JSON.parse(localStorage.getItem("msData"))
  return msData.poiObj[jobOrderData.RtnPOIId] || jobOrderData.RtnPlc || "-"
}

export const jobHasDriverColumns = (handleSelect, mode) => [
  {
    field: 'AptTm',
    headerName: "เวลานัด",
    width: 100,
    valueGetter: (params) => params.value && dayjs(params.value),
    valueFormatter: (params) => params.value ? params.value.format("DD/MM HH:mm") : "-",
  },
  {
    field: 'CusSNm',
    headerName: 'ลูกค้า',
    renderCell: ({ row, value }) => <BoxFR sx={{gap: 0}}>
      <Box width={3} height={25} bgcolor={row.IsExpanded?"primary.main": "transparent"}/>
      {row.IsExpanded ?
        <RemoveCircleRounded fontSize="small" color="primary" /> : <AddCircleRounded fontSize="small" color="primary" />}
      {value}
    </BoxFR>
  },
  {
    field: 'JobOrdId',
    headerName: 'เลขที่ใบงาน',
    width: 90,
  },
  {
    field: 'ContNo',
    headerName: 'หมายเลขตู้',
    width: 120,
  },
  {
    field: 'JobNo',
    headerName: 'JobNo',
    flex: 1,
    renderCell: (params) => {
      //make rtl text in div with textoverflow ellipsis
      return <div style={{ direction: "rtl", overflow: "hidden", textOverflow: "ellipsis" }}>{params.value}</div>
    }
  },
  {
    field: 'ContSizeFull',
    headerName: 'ขนาดตู้',
    width: 70,
  },
  {
    field: 'Loc',
    headerName: "สถานที่",
    flex: 1,
    valueGetter: (params) => !params.row.IsExpanded ? ""
      : mode === "Loc" ? locText(params.row) : rtnText(params.row)
  },
  {
    field: 'RtnDteEn',
    headerName: "Closing",
    width: 100,
    valueGetter: (params) => params.value && dayjs(params.value),
    valueFormatter: (params) => params.value ? params.value.format("DD/MM HH:mm") : "-",
  },
  {
    field: 'Count',
    headerName: 'จำนวน',
    valueGetter: (params) => params.row.JobOrdIds.length,
    valueFormatter: (params) => params.value.toString(),
    width: 50,
    align: "center"
  },
  {
    field: "actions",
    type: "actions",
    minWidth: 0,
    hide: !handleSelect,
    width: 30,
    getActions: (params) => [
      <GridActionsCellItem
        disabled={!params.row.IsExpanded}
        icon={<ArrowCircleRightIcon color={params.row.IsExpanded ? "primary" : "disabled"} sx={{ fontSize: 25 }} />}
        label="Select"
        onClick={() => handleSelect(params.row)}
      />
    ],
  }
]
export const summaryColumns = [
  {
    field: 'No',
    headerName: 'ลำดับ',
    width: 60,
    align: 'center',
  },
  {
    field: 'TagNm',
    headerName: 'Tag',
    width: 100,
  },
  {
    field: 'Bkg',
    headerName: 'Booking',
    width: 120,
  },
  {
    field: 'Agnt',
    headerName: 'Agent',
    width: 100,
  },
  {
    field: 'ContSizeFull',
    headerName: 'ขนาดตู้',
    width: 80,
  },
  {
    field: 'Loc',
    headerName: "สถานที่",
    flex: 1,
    valueGetter: (params) => `${params.row.TakePlc || ""}${params.row.Loc === '-' ? " - " : `-${params.row.Loc}-`}${params.row.RtnPlc || ""}`,
  },
  {
    field: 'Count',
    headerName: 'จำนวน',
    valueGetter: (params) => params.row.JobOrdIds.length,
    valueFormatter: (params) => params.value.toString(),
    width: 80,
    align: "center"
  },
]

export const driverColumns = (handleRemoveJobOrder, handleBkgClick, handleJobOrdIdClick, filterData, selectionPrint, setSelectionPrint, showCardDetail, colsWidth, drvFree, drvAbsent, drvDoOther) => {
  const handles = { handleRemoveJobOrder, handleBkgClick, handleJobOrdIdClick, filterData, selectionPrint, setSelectionPrint, showCardDetail }

  return [
    {
      field: 'NName',
      headerName: 'พขร.',
      width: colsWidth?.NName ?? 100,
      valueGetter: (params) => ` ${params.row.NName}`,
      renderCell: (params) => <BoxFC sx={{gap: 0, alignItems: "center"}}>
        <Typography variant="body2">{params.value}</Typography>
        <Typography variant="body2">{params.row.TukCd}</Typography>
      </BoxFC>
    },
    {
      field: 'Batt',
      headerName: 'Battery',
      width: colsWidth?.Batt ?? 60,
      renderCell: (params) => <BatteryColor percentage={params.value} width={55} />,
    },
    {
      field: 'yd',
      headerName: `วันก่อน\n(ว่าง ${drvFree?.yd || ""} ลา ${drvAbsent?.yd || 0}/${drvFree?.all || ""} คน)`,
      flex: colsWidth?.yd > 0 ? null : 1,
      width: colsWidth?.yd ?? 180,
      minWidth: 180,
      renderHeader: (params) => (
        <BoxFC sx={{ gap: 0, alignItems: "center", flex: 1, width: "100%" }}>
          <Typography variant="body2">วันก่อน</Typography>
          <Typography variant="body2">{drvFree?.all || 0} คน (ว่าง {drvFree?.yd || 0}, ติด {drvDoOther?.yd || 0}, ลา {drvAbsent?.yd || 0})</Typography>
        </BoxFC>
      ),
      valueGetter: (params) => params.row.yd.map(item => `${item.Agnt}-${item.Bkg}, ${item.ContSizeFull} ${item.TakePlc}-${item.Loc}-${item.RtnPlc}`).join("\n"),
      renderCell: (params) => <DataBox dataArr={params.row.yd} handles={handles} />,
    },
    {
      field: 'td',
      headerName: `วันนี้ (ว่าง ${drvFree?.td || ""} ลา ${drvAbsent?.td || 0}/${drvFree?.all || ""} คน)`,
      flex: colsWidth?.td ? null : 1,
      minWidth: 180,
      cellClassName: "today",
      renderHeader: (params) => (
        <BoxFC sx={{ gap: 0, alignItems: "center", flex: 1, width: "100%" }}>
          <Typography variant="body2">วันนี้</Typography>
          <Typography variant="body2">{drvFree?.all || 0} คน (ว่าง {drvFree?.td || 0}, ติด {drvDoOther?.td || 0}, ลา {drvAbsent?.td || 0})</Typography>
        </BoxFC>
      ),
      valueGetter: (params) => params.row.td.map(item => `${item.Agnt}-${item.Bkg}, ${item.ContSizeFull} ${item.TakePlc}-${item.Loc}-${item.RtnPlc}`).join("\n"),
      renderCell: (params) => <DataBox dataArr={params.row.td} handles={handles} />,
    },
    {
      field: 'tm',
      headerName: `พรุ่งนี้ (ว่าง ${drvFree?.tm || ""} ลา ${drvAbsent?.tm || 0}/${drvFree?.all || ""} คน)`,
      flex: colsWidth?.tm ? null : 1,
      minWidth: 180,
      cellClassName: "tomorrow",
      renderHeader: (params) => (
        <BoxFC sx={{ gap: 0, alignItems: "center", flex: 1, width: "100%" }}>
          <Typography variant="body2">พรุ่งนี้</Typography>
          <Typography variant="body2">{drvFree?.all || 0} คน (ว่าง {drvFree?.tm || 0}, ติด {drvDoOther?.tm || 0}, ลา {drvAbsent?.tm || 0})</Typography>
        </BoxFC>
      ),
      valueGetter: (params) => params.row.tm.map(item => `${item.Agnt}-${item.Bkg}, ${item.ContSizeFull} ${item.TakePlc}-${item.Loc}-${item.RtnPlc}`).join("\n"),
      renderCell: (params) => <DataBox dataArr={params.row.tm} handles={handles} />,
    },
    {
      field: 'atm',
      headerName: `วันหน้า (ว่าง ${drvFree?.atm || ""} ลา ${drvAbsent?.atm || 0}/${drvFree?.all || ""} คน)`,
      flex: colsWidth?.atm ? null : 1,
      minWidth: 180,
      renderHeader: (params) => (
        <BoxFC sx={{ gap: 0, alignItems: "center", flex: 1, width: "100%" }}>
          <Typography variant="body2">วันหน้า</Typography>
          <Typography variant="body2">{drvFree?.all || 0} คน (ว่าง {drvFree?.atm || 0}, ติด {drvDoOther?.atm || 0}, ลา {drvAbsent?.atm || 0})</Typography>
        </BoxFC>
      ),
      valueGetter: (params) => params.row.atm.map(item => `${item.Agnt}-${item.Bkg}, ${item.ContSizeFull} ${item.TakePlc}-${item.Loc}-${item.RtnPlc}`).join("\n"),
      renderCell: (params) => <DataBox dataArr={params.row.atm} handles={handles} />,
    },
  ]
}

export const printColumnHeader = (msData) => [
  {
    id: "NName",
    name: "NName",
    prompt: "พขร",
    width: 1.9,
    align: "left",
    padding: 0,
    separateRow: "-"
  },
  {
    id: "BL",
    name: "BL",
    prompt: msData.tags.find(tag => tag.TagId === 1)?.Name,
    width: 2.1,
    align: "left",
    padding: 0,
    separateRow: "-"
  },
  {
    id: "BB",
    name: "BB",
    prompt: `${msData.tags.find(tag => tag.TagId === 2)?.Name},${msData.tags.find(tag => tag.TagId === 5)?.Name}`,
    width: 2.1,
    align: "left",
    padding: 0,
    separateRow: "-"
  },
  {
    id: "LB",
    name: "LB",
    prompt: msData.tags.find(tag => tag.TagId === 3)?.Name,
    width: 2.1,
    align: "left",
    padding: 0,
    separateRow: "-"
  },
  {
    id: "DX",
    name: "DX",
    prompt: "เงิน",
    width: 0.95,
    align: "right",
    padding: 0,
    separateRow: "-"
  },
  {
    id: "Toll",
    name: "Toll",
    prompt: "ทางด่วน",
    width: 0.95,
    align: "right",
    padding: 0,
    separateRow: "-"
  },
]

export const printJobColumnHeader = [
  {
    id: "Tag",
    name: "Tag",
    prompt: "Tag",
    width: 1,
    align: "left",
    padding: 0,
    separateRow: "-"
  },
  {
    id: "Bkg",
    name: "Bkg",
    prompt: "Booking",
    width: 1.4,
    align: "left",
    padding: 0,
    separateRow: "-"
  },
  {
    id: "Agnt",
    name: "Agnt",
    prompt: "Agent",
    width: 0.8,
    align: "left",
    padding: 0,
    separateRow: "-"
  },
  {
    id: "ContSizeFull",
    name: "ContSizeFull",
    prompt: "ขนาดตู้",
    width: 0.7,
    align: "left",
    padding: 0,
    separateRow: "-"
  },
  {
    id: "Loc",
    name: "Loc",
    prompt: "สถานที่",
    width: 2.4,
    align: "left",
    padding: 0,
    separateRow: "-"
  },
  {
    id: "TakeDteSt",
    name: "TakeDteSt",
    prompt: "First",
    width: 0.7,
    align: "left",
    padding: 0,
    separateRow: "-"
  },
  {
    id: "TakeDteEn",
    name: "TakeDteEn",
    prompt: "Last",
    width: 0.7,
    align: "left",
    padding: 0,
    separateRow: "-"
  },
  {
    id: "IsRdyTake",
    name: "IsRdyTake",
    prompt: "พร้อมรับ",
    width: 0.7,
    align: "left",
    padding: 0,
    separateRow: "-"
  },
  {
    id: "Count",
    name: "Count",
    prompt: "จำนวน(เที่ยว)",
    width: 1,
    align: "center",
    padding: 0,
    separateRow: "-"
  },
]

export const driverStatisticColumns = [
  {
    field: 'Header',
    headerName: 'รายการ',
    width: 130,
  },
  {
    field: 'Num',
    headerName: 'จำนวน',
    flex: 1,
  },
  {
    field: 'Avg',
    headerName: 'เฉลี่ย',
    flex: 1,
  },
  {
    field: 'Min',
    headerName: 'Min',
    flex: 1,
  },
  {
    field: 'Max',
    headerName: 'Max',
    flex: 1,
  },
  {
    field: 'No',
    headerName: 'ลำดับที่',
    flex: 1,
  },
  {
    field: "actions",
    type: "actions",
    minWidth: 0,
    width: 30,
    getActions: (params) => [
      <GridActionsCellItem
        icon={<GroupRounded />}
        label="Select"
        onClick={() => { }}
      />
    ],
  }
]

export const jobOrderColumns = [
  {
    field: "No",
    headerName: "No.",
    width: 40,
    align: "center",
  },
  {
    field: "Bkg",
    headerName: jobOrderColNm.Bkg,
    width: 90,
  },
  {
    field: "AptTm",
    headerName: "เวลานัด",
    width: 100,
    type: 'dateTime',
    valueFormatter: (params) => params.value ? dayjs(params.value).format("DD/MM HH:mm") : "-",
  },
  {
    field: "ContNo",
    headerName: jobOrderColNm.ContNo,
    width: 120,
  },
  {
    field: "ContSizeFull",
    headerName: jobOrderColNm.ContSize,
    width: 70,
  },
  {
    field: "Location",
    headerName: "สถานที่",
    valueGetter: (params) => locationText(params.row),
    flex: 1,
  }
]
export const expenseColumns = (handleRemoveRow) => {
  const msData = JSON.parse(localStorage.getItem("msData"))
  return [
    {
      field: "ExpDte",
      headerName: dailyExpenseColNm.ExpDte,
      width: 100,
      type: 'dateTime',
      valueGetter: (params) => params.value && dayjs(params.value),
      valueFormatter: (params) => params.value ? params.value.format("DD/MM HH:mm") : "-",
    },
    {
      field: "ExpId",
      headerName: "ค่าใช้จ่าย",
      valueGetter: (params) => msData.expObj[params.value]?.ExpNm || "-",
      flex: 1
    },
    {
      field: "Amnt",
      headerName: dailyExpenseColNm.Amnt,
      width: 80,
      align: "right",
      valueFormatter: (params) => params.value ? numberFormat(parseFloat(params.value)) : "0",
    },
    {
      field: "SumDalyExpDocAmnt",
      headerName: "ยอดรวมเอกสาร",
      width: 110,
      align: "right",
      valueFormatter: (params) => params.value ? numberFormat(params.value) : "0",
    },
    {
      field: "ChngeAmnt",
      headerName: "เงินทอน/เกิน",
      width: 90,
      align: "right",
      valueFormatter: (params) => params.value ? numberFormat(params.value) : "0",
    },
    {
      field: "ChngClrTm",
      headerName: "Clearเงินทอน",
      width: 100,
      type: 'dateTime',
      valueGetter: (params) => params.value && dayjs(params.value),
      valueFormatter: (params) => params.value ? params.value.format("DD/MM HH:mm") : "-",
    },
    {
      field: 'actions',
      type: 'actions',
      width: 30,
      getActions: (params) => [
        <GridActionsCellItem
          icon={<RemoveCircleRounded />}
          label="Clear"
          onClick={() => handleRemoveRow(params.row)}
        />
      ]
    }
  ]
}

export const clearJobOrderDocColumns = (toggleContSts, msData) => [
  {
    field: "No",
    headerName: "No.",
    width: 40,
    align: "center",
  },
  {
    field: "JobNo",
    headerName: jobOrderColNm.JobNo,
    width: 100,
  },
  {
    field: "JobOrdId",
    headerName: jobOrderColNm.JobOrdId,
    width: 90,
  },
  {
    field: "AptTm",
    headerName: "เวลานัด",
    width: 100,
    type: 'dateTime',
    valueFormatter: (params) => params.value ? dayjs(params.value).format("DD/MM HH:mm") : "-",
  },
  {
    field: "ContNo",
    headerName: jobOrderColNm.ContNo,
    width: 120,
  },
  {
    field: "ContSizeFull",
    headerName: jobOrderColNm.ContSize,
    width: 70,
  },
  {
    field: "Location",
    headerName: "สถานที่",
    valueGetter: (params) => locationText(params.row),
    flex: 1,
  },
  // {
  //   field: "ContSts",
  //   headerName: "สถานะ",
  //   width: 120,
  //   renderCell: (params) => {

  //     const contStsesMaster = msData.containerStatuses.filter(sts => !sts.IsHide)
  //     const contStses = params.row.JOCS || []
  //     const contStsObj = contStses.reduce((prev, cur) => {
  //       prev[cur.ContStsId] = cur
  //       return prev
  //     }, {})
  //     return (
  //       <BoxFR sx={{ gap: 0 }}>
  //         {contStsesMaster.map(contSts => {
  //           const contStsId = contSts.ContStsId
  //           return contStsObj[contStsId] ?
  //             <IconButton key={contStsId} sx={{ p: 0 }} onClick={toggleContSts&& toggleContSts(contStsObj[contStsId])}>
  //               {contStsObj[contStsId].IsCmpt ?
  //                 <CheckCircleRounded color={contSts.BgClr} />
  //                 :
  //                 <Lens color={contSts.BgClr} />
  //               }
  //             </IconButton>
  //             :
  //             <PanoramaFishEye key={contStsId} color={contSts.BgClr} />
  //         })}
  //       </BoxFR>
  //     )
  //   }
  // },
]