import { Box, Button, ButtonGroup, Dialog, DialogContent, TextField, Typography } from "@mui/material"
import { branchApiNm } from "branch/constant/branchApiNm"
import { BoxFC, BoxFR } from "components/BoxCustom"
import PaperComponent from "components/PaperComponent"
import { DialogFooter, DialogHeader } from "components/dialog/DialogHeader"
import { GlobalStateContext } from "contexts/GlobalStateContext"
import React, { useCallback, useContext, useEffect, useMemo, useState } from "react"
import { addSumRow, getSumColorClassName, sxSumColorClass } from "utils/highlightSumColor";
import { invExpDetailColumns, invJODetailColumns } from "./columns"
import DataGridCellExpand from "components/DataGridCellExpand/DataGridCellExpand"
import { addIdForDataGrid } from "utils"
import dayjs from "dayjs"
import { branchNoText } from "utils/branchNoText"

const initDialogData = {
  InvId: null,
  InvNo: "",
  InvDte: "",
  CusNm: "",
  Addr: "",
  TaxId: "",
  BrchNo: "",
}
const InvoiceDetailDialog = ({ invId, dialogOpen, setDialogOpen }) => {

  const { ax } = useContext(GlobalStateContext)
  const [dialogData, setDialogData] = useState({ ...initDialogData })
  const [joData, setJOData] = useState([])
  const [expData, setExpData] = useState([])
  const [tableView, setTableView] = useState("EXP")

  const textFieldProps = useCallback((name, label) => ({
    label: label,
    size: "small",
    value: dialogData[name],
  }), [dialogData])


  const modJobOrdDataTable = useMemo(() => {
    return addSumRow({
      dataTable: joData,
      sumColNms: ["TranAmnt", "ShorAmnt", "DepoAmnt", "GateAmnt", "GateDepoAmnt", "ClenAmnt", "OtAmnt", "XRayAmnt", "OthOwnRcptAmnt", "OthCusrcptAmnt", "Total"],
      sumColTotal: "JobSNm"
    })
  }, [joData])

  const modExpDataTable = useMemo(() => {
    return addSumRow({
      dataTable: expData,
      sumColNms: ["Total"],
      sumColTotal: "ExpNm"
    })
  }, [expData])
  useEffect(() => {
    if (dialogOpen) {
      ax.post(branchApiNm.getInvoiceDetail, { InvId: invId }).then(value => {
        if (value.data) {
          const invData = value.data.invData
          setDialogData({
            ...invData,
            InvDte: invData.InvDte ? dayjs(invData.InvDte).format("DD/MM/YYYY") : "",
            BrchNo: branchNoText(invData.CusNm, invData.BrchNo)
          })
          setJOData(addIdForDataGrid(value.data.joData, "JobOrdId"))
          setExpData(value.data.expData.map((item, index) => ({ ...item, No: index + 1, id: index+1 })))
        }
      })
    }
  }, [dialogOpen, ax, invId])

  return (
    <Dialog
      open={dialogOpen}
      PaperComponent={PaperComponent}
      aria-labelledby="draggable-dialog-title"
      fullWidth maxWidth='xl'
    >
      <DialogHeader handleDialogClose={() => setDialogOpen(false)}
        title={`รายละเอียดใบแจ้งหนี้ ${dialogData.InvNo} `}
        onDeleteClick={null}
      />
      <DialogContent>
        <BoxFC pt={2} >
          <BoxFR>
            <TextField {...textFieldProps("CusNm", "ลูกค้า")} sx={{ flex: 1 }} />
            <TextField {...textFieldProps("InvDte", "วันที่ใบแจ้งหนี้")} sx={{ width: 150 }} />
            <TextField {...textFieldProps("InvNo", "เลขที่ใบแจ้งหนี้")} sx={{ width: 150 }} />
          </BoxFR>
          <TextField {...textFieldProps("Addr", "ที่อยู่")} fullWidth multiline rows={3} />
          <BoxFR>
            <TextField {...textFieldProps("TaxId", "เลขประจำตัวผู้เสียภาษี")} sx={{ width: 150 }} />
            <TextField {...textFieldProps("BrchNo", "สาขา")} sx={{ width: 150 }} />
            <Box flex={1} />
            <Typography fontWeight="bold">มุมมองตาราง :</Typography>
            <ButtonGroup>
            <Button variant={tableView === "EXP" ? "contained" : "outlined"} onClick={() => setTableView("EXP")}>สรุปค่าใช้จ่าย</Button>
              <Button variant={tableView === "JO" ? "contained" : "outlined"} onClick={() => setTableView("JO")}>รายการตู้</Button>
            </ButtonGroup>
          </BoxFR>
          {tableView === "EXP" ?

            <Box height={500} sx={sxSumColorClass}>
              <DataGridCellExpand
                hideFooter
                rows={modExpDataTable}
                columns={invExpDetailColumns}
                getRowClassName={getSumColorClassName}
              />
            </Box>
            :
            <Box height={500} sx={sxSumColorClass}>
              <DataGridCellExpand
                hideFooter
                rows={modJobOrdDataTable}
                columns={invJODetailColumns}
                getRowClassName={getSumColorClassName}
              />
            </Box>
          }
        </BoxFC>

      </DialogContent>
      <DialogFooter okText={""}
        handleDialogClose={() => setDialogOpen(false)}
        handleDialogOk={null} />
    </Dialog>
  )
}

export default React.memo(InvoiceDetailDialog)