import dayjs from "dayjs"

export const clearFilterData = {
  PCPrdTmSt: null,
  PCPrdTmEn: null
}
export const initFilterData = {
  PCPrdTmSt: dayjs().add(-30, 'day').format('YYYY-MM-DD 00:00:00'),
  PCPrdTmEn: dayjs().format('YYYY-MM-DD 23:59:59')
}

export const initPettyCashBalanceData = {
  PCPrdId: null,
  PCPrdTm: dayjs().format('YYYY-MM-DD HH:mm'),
  PCPrdSt: null,
  PCPrdEn: null,
  PCJnlId: null,
  InitAmnt: "",
  FinAmnt: null,
  AdmAccId: null,
  AdmNNm: "",
  Rmk: "",
  PCTrns: [],
}
