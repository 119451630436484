import React, { forwardRef, useCallback } from "react";

import { GoogleMap, useJsApiLoader, Marker } from '@react-google-maps/api';
import { useImperativeHandle } from "react";

const containerStyle = {
  width: '100%',
  height: '100%'
};

export const LAEMCHABANG_OUT_GATE_LOC = {lat:13.087725, lng:100.894545}
export const LAEMCHABANG_SEA_PORT = {lat:13.072172, lng:100.903980}
const GoogleMapWrapper = forwardRef (({ onMarkerDragEnd, onDropPinCenter, initMapCenter, initMarkerPosition}, ref) => {
  const [map, setMap] = React.useState(null)
  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: "AIzaSyDgvxihvJPXifWy6F1T3lY5uF2vjctF4XU"
  })

  const onLoad = useCallback(function callback(map) {
    setMap(map)
  }, [])

  const onUnmount = useCallback(function callback(map) {
    setMap(null)
  }, [])

  const handleDragMarkerEnd = useCallback((e) => {
    const position = { lat: e.latLng.lat(), lng: e.latLng.lng() }
    if (onMarkerDragEnd) {
      onMarkerDragEnd(position)
    }
    // 
  }, [onMarkerDragEnd])

  useImperativeHandle(ref, ()=>{
    return {
      test: ()=>{console.log("test from GoogleMapWrapper")},
      pinCenter: ()=> {
        console.log("map center", map.getCenter())
        if(onDropPinCenter){
          onDropPinCenter({ lat: map.getCenter().lat(), lng: map.getCenter().lng() })
        }
      }
    }
  })
  return isLoaded ?
    <GoogleMap
      mapContainerStyle={containerStyle}
      center={initMapCenter ? initMapCenter : LAEMCHABANG_SEA_PORT}
      zoom={10}
    onLoad={onLoad}
    onUnmount={onUnmount}
    >
      { /* Child components, such as markers, info windows, etc. */}
      {initMarkerPosition && initMarkerPosition.lat && initMarkerPosition.lng &&
        <Marker draggable
          position={initMarkerPosition}
          onDragEnd={handleDragMarkerEnd} />
      }
    </GoogleMap> : <></>
}, [])

export default React.memo(GoogleMapWrapper)