import { Box, Typography } from "@mui/material";
import { green, red, yellow } from "@mui/material/colors";
import React from "react";


const BatteryColor = ({ percentage, width, height = 20 }) => {
  const borderColor = percentage > 50 ? green[600] : percentage > 25 ? yellow[800] : red[600]
  const color = percentage > 50 ? green[400] : percentage > 25 ? yellow[600] : red[400]
  return (
    <Box width={width} sx={{
      border: `1px solid ${borderColor}`,
      borderRadius: 1,
      position: "relative",
      height: height,
    }}>
      {percentage &&
        <Box sx={{
          p: 0.5,
          width: `${percentage || 0}%`,
          bgcolor: color,
          borderRadius: 1,
          height: height,
        }} />
      }
      <Typography variant="caption" sx={{position: "absolute", top: 0, bottom:0, left: 2, right: 0}} >{percentage || "-"}%</Typography>
    </Box>
  );
}

export default BatteryColor;