import { ArticleRounded, AttachFileRounded } from "@mui/icons-material";
import { Button, IconButton, Paper, Typography } from "@mui/material";
import { BoxFC, BoxFR } from "components/BoxCustom";
import PopoverDefault from "components/PopoverDefault";
import dayjs from "dayjs";
import { numberFormat } from "utils";


export const invoiceReviewColumns = ({ handleDetailClick }) => [
  {
    field: "No",
    headerName: "No.",
    width: 50,
  },
  {
    field: "CusSNm",
    headerName: "ลูกค้า",
    width: 180,
  },
  {
    field: "InvDte",
    headerName: "วันที่ใบแจ้งหนี้",
    width: 100,
    type: "dateTime",
    valueFormatter: (params) => {
      return params.value ? dayjs(params.value).format("DD/MM/YYYY") : "-";
    },
  },
  {
    field: "InvNo",
    headerName: "เลขที่ใบแจ้งหนี้",
    width: 120,
  },
  {
    field: "NumJobOrd",
    headerName: "จำนวนตู้",
    width: 90,
    align: "center"
  },
  {
    field: "TranAmnt",
    headerName: "ค่าขนส่ง",
    align: "right",
    valueFormatter: (params) => params.value ? numberFormat(params.value) : "0",
    width: 100,
  },
  {
    field: "ShorAmnt",
    headerName: "ค่าชอร์",
    align: "right",
    valueFormatter: (params) => params.value ? numberFormat(params.value) : "0",
    width: 100,
  },
  {
    field: "DepoAmnt",
    headerName: "ค่ายกตู้เปล่า",
    align: "right",
    valueFormatter: (params) => params.value ? numberFormat(params.value) : "0",
    width: 100,
  },
  {
    field: "GateAmnt",
    headerName: "ค่าผ่านท่า",
    align: "right",
    valueFormatter: (params) => params.value ? numberFormat(params.value) : "0",
    width: 100,
  },
  {
    field: "GateDepoAmnt",
    headerName: "ค่าผ่านลาน",
    align: "right",
    valueFormatter: (params) => params.value ? numberFormat(params.value) : "0",
    width: 100,
  },
  {
    field: "ClenAmnt",
    headerName: "ค่าล้างตู้",
    align: "right",
    valueFormatter: (params) => params.value ? numberFormat(params.value) : "0",
    width: 100,
  },
  {
    field: "OtAmnt",
    headerName: "ค่าล่วงเวลา",
    align: "right",
    valueFormatter: (params) => params.value ? numberFormat(params.value) : "0",
    width: 100,
  },
  {
    field: "XRayAmnt",
    headerName: "ค่า X-Ray",
    align: "right",
    valueFormatter: (params) => params.value ? numberFormat(params.value) : "0",
    width: 100,
  },
  {
    field: "OthOwnRcptAmnt",
    headerName: "ใบเสร็จบริษัทอื่นๆ",
    align: "right",
    valueFormatter: (params) => params.value ? numberFormat(params.value) : "0",
    width: 120,
    renderCell: (params) => (
      <NumberWithIcon value={numberFormat(params.value)} fn={handleDetailClick(params.row, "OthOwnRcptAmnt")} />
    )
  },
  {
    field: "OthCusRcptAmnt",
    headerName: "ใบเสร็จลูกค้าอื่นๆ",
    align: "right",
    valueFormatter: (params) => params.value ? numberFormat(params.value) : "0",
    width: 120,
    renderCell: (params) => (
      <NumberWithIcon value={numberFormat(params.value)} fn={handleDetailClick(params.row, "OthCusRcptAmnt")} />
    )
  },
  {
    field: "Total",
    headerName: "Total",
    align: "right",
    valueFormatter: (params) => params.value ? numberFormat(params.value) : "0",
    width: 100,
  },
  {
    field: "NumFile",
    headerName: "Files",
    valueFormatter: (params) => `${params.value || 0}`,
    width: 80,
    align: "center",
    renderCell: (params) => (
      <BoxFR>
        <Typography variant="body2">{params.value}</Typography>
        <PopoverDefault icon={<AttachFileRounded size="small" color={+params.value === 0 ? "disabled" : "primary"} />}>
          <Paper sx={{ p:2 }}>
            <BoxFC>
              {params.row.Files && params.row.Files.map(file => (
                <Button key={file.InvFileId} variant="outlined"
                  onClick={() => window.open(`${window.location.origin}/file/${file.InvId}/${file.FileNm}`)}>
                  {file.Label}
                </Button>
              ))}
            </BoxFC>
          </Paper>
        </PopoverDefault>
      </BoxFR>
    )
  },
  {
    field: "Rmk",
    headerName: "หมายเหตุ",
    flex: 1,
    minWidth: 120,
  },
]

export const expenseJobDetailColumns = [
  {
    field: "No",
    headerName: "No.",
    width: 50,
  },
  {
    field: "ExpNm",
    headerName: "ค่าใช้จ่าย",
    width: 120,
  },
  {
    field: "JobOrdId",
    headerName: "เลขที่ใบงาน",
    width: 100,
  },
  {
    field: "ContNo",
    headerName: "หมายเลขตู่้",
    width: 120,
  },
  {
    field: "ContSizeFull",
    headerName: "ขนาดตุ้",
    width: 70,
  },
  {
    field: "Total",
    headerName: "จำนวนวเงิน",
    align: "right",
    valueFormatter: (params) => params.value ? numberFormat(params.value) : "0",
    width: 120,
  },
  {
    field: "Rmk",
    headerName: "หมายเหตุ",
    flex: 1,
    minWidth: 120,
  },
  {
    field: "JORmk",
    headerName: "หมายเหตุใบงาน",
    flex: 2,
    minWidth: 120,
  },
]

export const invExpDetailColumns = [
  {
    field: "No",
    headerName: "No.",
    width: 50,
  },
  {
    field: "ExpNm",
    headerName: "ค่าใช้จ่าย",
    flex: 1,
    minWidth: 120,
  },
  {
    field: "Qty",
    headerName: "จำนวน",
    width: 80,
    align: "center"
  },
  {
    field: "UntPr",
    headerName: "ราคาต่อหน่วย",
    align: "right",
    valueFormatter: (params) => params.value ? numberFormat(params.value) : "",
    width: 150,
  },
  {
    field: "Total",
    headerName: "จำนวนวเงิน",
    align: "right",
    valueFormatter: (params) => params.value ? numberFormat(params.value) : "0",
    width: 200,
  },
  {
    field: "Rmk",
    headerName: "หมายเหตุ",
    flex: 1,
    minWidth: 120,
  },
]
export const invJODetailColumns = [
  {
    field: "No",
    headerName: "No.",
    width: 50,
  },
  {
    field: "JobSNm",
    headerName: "งาน",
    width: 120,
  },
  {
    field: "JobNo",
    headerName: "Job No.",
    width: 130,
  },
  {
    field: "Bkg",
    headerName: "Bookig",
    width: 130,
  },
  {
    field: "JobOrdId",
    headerName: "เลขที่ใบงาน",
    width: 90,
  },
  {
    field: "ContSizeFull",
    headerName: "ขนาดตู้",
    width: 70,
  },
  {
    field: "TakePlc",
    headerName: "สถานที่รับ",
    width: 90,
  },
  {
    field: "Loc",
    headerName: "สถานที่ส่ง",
    width: 90,
  },
  {
    field: "RtnPlc",
    headerName: "สถานที่คืน",
    width: 90,
  },
  {
    field: "AptTm",
    headerName: "เวลานัด",
    width: 100,
    type: "dateTime",
    valueFormatter: (params) => {
      return params.value ? dayjs(params.value).format("DD/MM HH:mm") : "-";
    },
  },
  {
    field: "FinDte",
    headerName: "เวลาลงจบ",
    width: 100,
    type: "dateTime",
    valueFormatter: (params) => {
      return params.value ? dayjs(params.value).format("DD/MM HH:mm") : "-";
    },
  },
  {
    field: "TranAmnt",
    headerName: "ค่าขนส่ง",
    align: "right",
    valueFormatter: (params) => params.value ? numberFormat(params.value) : "0",
    width: 100,
  },
  {
    field: "ShorAmnt",
    headerName: "ค่าชอร์",
    align: "right",
    valueFormatter: (params) => params.value ? numberFormat(params.value) : "0",
    width: 100,
  },
  {
    field: "DepoAmnt",
    headerName: "ค่ายกตู้เปล่า",
    align: "right",
    valueFormatter: (params) => params.value ? numberFormat(params.value) : "0",
    width: 100,
  },
  {
    field: "GateAmnt",
    headerName: "ค่าผ่านท่า",
    align: "right",
    valueFormatter: (params) => params.value ? numberFormat(params.value) : "0",
    width: 100,
  },
  {
    field: "GateDepoAmnt",
    headerName: "ค่าผ่านลาน",
    align: "right",
    valueFormatter: (params) => params.value ? numberFormat(params.value) : "0",
    width: 100,
  },
  {
    field: "ClenAmnt",
    headerName: "ค่าล้างตู้",
    align: "right",
    valueFormatter: (params) => params.value ? numberFormat(params.value) : "0",
    width: 100,
  },
  {
    field: "OtAmnt",
    headerName: "ค่าล่วงเวลา",
    align: "right",
    valueFormatter: (params) => params.value ? numberFormat(params.value) : "0",
    width: 100,
  },
  {
    field: "XRayAmnt",
    headerName: "ค่า X-Ray",
    align: "right",
    valueFormatter: (params) => params.value ? numberFormat(params.value) : "0",
    width: 100,
  },
  {
    field: "OthOwnRcptAmnt",
    headerName: "ใบเสร็จบริษัทอื่นๆ",
    align: "right",
    valueFormatter: (params) => params.value ? numberFormat(params.value) : "0",
    width: 120,
  },
  {
    field: "OthCusrcptAmnt",
    headerName: "ใบเสร็จลูกค้าอื่นๆ",
    align: "right",
    valueFormatter: (params) => params.value ? numberFormat(params.value) : "0",
    width: 120,
  },
  {
    field: "Total",
    headerName: "Total",
    align: "right",
    valueFormatter: (params) => params.value ? numberFormat(params.value) : "0",
    width: 100,
  },
  {
    field: "Rmk",
    headerName: "หมายเหตุ",
    flex: 1,
    minWidth: 120,
  },
]
function NumberWithIcon({ value, fn }) {
  return (
    <BoxFR>
      <Typography variant="body2">{value}</Typography>
      <IconButton onClick={fn} size="small" disabled={+value === 0}>
        <ArticleRounded color={+value === 0 ? "disabled" : "primary"} />
      </IconButton>
    </BoxFR>
  )
}