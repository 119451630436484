import { BoxFC, BoxFR } from "components/BoxCustom";
import React, { useCallback, useContext, useEffect, useMemo, useState } from "react";
import { initFilterData } from "./initData";
import { GlobalStateContext } from "contexts/GlobalStateContext";
import SearchButton from "components/buttons/SearchButton";
import ClearButton from "components/buttons/ClearButton";
import ComboBox from "components/ComboBox";
import DateTimeSelect from "components/DateTimeSelect";
// import { branchApiNm } from "../ExtraMainLayoutConstant";
// import { addIdForDataGrid } from "utils";
import { addSumRow, sxSumColorClass } from "utils/highlightSumColor";
import { columns } from "./columns";
import { Box, Button, Typography } from "@mui/material";
import DataGridCellExpand from "components/DataGridCellExpand/DataGridCellExpand";
import DailyJobDialogV2 from "pages/Menu1/DailyJob/DailyJobDialogV2";
import { printReport } from "./printReport";
import dayjs from "dayjs";
import { numberFormatNoFlotingPoint } from "utils";
import { branchApiNm } from "branch/constant/branchApiNm";

let selectedJobOrdId = null

const TruckIncomeSummary = () => {
  const { ax, msData } = useContext(GlobalStateContext)

  const [filterData, setFilterData] = useState({ ...initFilterData })
  const [jobCombo, setJobCombo] = useState(msData.jobCombo)
  const [dataTable, setDataTable] = useState([])
  const [dailyJobDialogOpen, setDailyJobDialogOpen] = useState(false)
  const [summaryTruck, setSummerTruck] = useState({
    allTruck: 0,
    allDriver: 0,
    runTruck: 0,
    runDriver: 0,
    idleTruck: 0,
    truckNoDriver: 0, 
    idleDriver: 0
  })

  const modDataTable = useMemo(() => {
    const result = addSumRow({
      dataTable: dataTable,
      sumColNms: ["NumJob", "SumTrnsUntPr"],
      sumColTotal: "TukCd"
    })
    const sumRow = result.find(row => row.id === 0)
    if (sumRow) {
      sumRow.AvgTrnsUntPr = +sumRow.SumTrnsUntPr / +sumRow.NumJob
    }
    return result
  }, [dataTable])

  const sumRow = useMemo(() => modDataTable.find(row => row.id === 0) || { id: -1, TukCd: "รวม" }, [modDataTable])

  const getData = useCallback((filter) => {
    ax.post(branchApiNm.getTruckIncomeSummary, filter).then(value => {
      const jobOrderData = value.data.jobOrderData
      const numAbsent = value.data.numAbsent
      const result = []
      let no = 0
      let idleTruck = 0
      let runTruck = 0
      let idleTruckNoDrv = 0
      const setRunDrvId = new Set()
      const allDrv = msData.driverOnlyCombo.length;
      for (const data of jobOrderData) {
        no++
        let isFirst = true
        if (!data.JobOrders) {
          result.push({
            id: data.TukId,
            No: no,
            ...data
          })
          idleTruck++
          if (!data.DrvNm) idleTruckNoDrv++
          continue
        }
        runTruck++
        for (const jo of data.JobOrders) {
          setRunDrvId.add(jo.DrvId)
          let row = {
            id: jo.JobOrdId,
            ...jo,
            NumJob: "",
            TukCd: "",
          }
          if (isFirst) {
            row.No = no
            row = {  ...row, ...data }
            isFirst = false
          }
          result.push(row)
        }
      }
      setSummerTruck({ 
        allTruck: no, 
        runTruck, 
        idleTruck, 
        idleTruckNoDrv, 
        allDriver: allDrv, 
        runDriver: setRunDrvId.size,  
        idleDriver: allDrv - setRunDrvId.size,
        numAbsent: numAbsent ||0
      })
      console.log("result", result)
      setDataTable(result)
    })
  }, [ax, msData.driverOnlyCombo])

  const setSelectDate = useCallback((start, end) => {
    setFilterData(o => ({
      ...o,
      AptTmSt: start,
      AptTmEn: end
    }))
  }, [])

  const handleCusComboChange = useCallback((id) => {
    setFilterData(o => ({ ...o, CusId: id, JobId: null }))
    if (!id) {
      setJobCombo(msData.jobCombo)
    } else {
      setJobCombo(msData.jobCombo.filter(job => job.refId === id))
    }
  }, [msData.jobCombo])

  const handleSearchButtonClick = useCallback(() => {
    console.log("filterData", filterData)
    getData(filterData)
  }, [getData, filterData])

  const handleClearButtonClick = useCallback(() => { }, [])

  const handlePrintReport = useCallback(() => {
    const printReportData = modDataTable.map(row => ({
      ...row,
      Loc: `${row.TakePlc || ""}-${row.Loc || ""}-${row.RtnPlc || ""}`,
      TukRmk: `${row.TukRmk || ""}${row.TukRmk ? `, ${row.Dscp || ""}` : row.Dscp || ""}`,
      TrnsUntPr: +row.TrnsUntPr ? numberFormatNoFlotingPoint(+row.TrnsUntPr) : "",
      SumTrnsUntPr: +row.SumTrnsUntPr ? numberFormatNoFlotingPoint(+row.SumTrnsUntPr) : "",
      AvgTrnsUntPr: +row.AvgTrnsUntPr ? numberFormatNoFlotingPoint(+row.AvgTrnsUntPr) : "",
    }))
    const sumRow = printReportData.find(row => row.id === 0)
    sumRow.TrnsUntPr = ""
    console.log("sumRow::", sumRow)
    printReport({ dataTable: printReportData, aptTm: dayjs(filterData.AptTmSt).format("DD/MM/YYYY"), msData, summaryTruck })
  }, [filterData, msData, modDataTable, summaryTruck])

  const onDailyJobDialogFinish = useCallback(() => {
    getData(filterData)
  }, [getData, filterData])

  const handleOpenDailyJobDialog = useCallback((jobOrdId) => {
    selectedJobOrdId = jobOrdId
    setDailyJobDialogOpen(true)
  }, [])

  useEffect(() => {
    getData({ ...initFilterData })
  }, [getData])
  console.log("TruckIncomeSummary modDataTable ::", modDataTable)
  return (
    <BoxFC height="100%" sx={{ position: "relative", gap:1}}>
      <BoxFR sx={{ position: "absolute", top: 0, left: 0 }}>
        <Button variant="contained" onClick={handlePrintReport}>พิมพ์ Report</Button>
      </BoxFR>
      <BoxFR width="100%" sx={{ justifyContent: "center" }}>
        <DateTimeSelect
          sx={{ width: 180 }}
          label="วันที่วิ่งงาน"
          start={filterData.AptTmSt}
          end={filterData.AptTmEn}
          setSelectDate={setSelectDate}
          getData={handleSearchButtonClick}
        />
        <ComboBox sx={{ width: 180 }} options={msData.cusCombo} label="ลูกค้า"
          selectedId={filterData.CusId}
          setSelectedId={handleCusComboChange}
        />
        <ComboBox sx={{ width: 180 }} options={jobCombo} label="งาน"
          selectedId={filterData.JobId}
          setSelectedId={(id) => setFilterData(o => ({ ...o, JobId: id }))}
        />
        <SearchButton onClick={handleSearchButtonClick} />
        <ClearButton onClick={() => handleClearButtonClick()} />
      </BoxFR>
      <BoxFR px={1}>
        <Typography width={170}><strong>รวมรถทั้งหมด:</strong> {summaryTruck.allTruck} คัน</Typography>
        <Typography width={80}><strong>วิ่งงาน:</strong> {summaryTruck.runTruck}</Typography>
        <Typography width={100}><strong>ไม่วิ่งงาน:</strong> {summaryTruck.idleTruck}</Typography>
        <Typography width={100}><strong>ไม่มีพขร:</strong> {summaryTruck.idleTruckNoDrv}</Typography>
      </BoxFR>
      <BoxFR px={1}>
        <Typography width={170}><strong>รวมพขรทั้งหมด:</strong>{summaryTruck.allDriver} คน</Typography>
        <Typography width={80}><strong>วิ่งงาน:</strong> {summaryTruck.runDriver}</Typography>
        <Typography width={100}><strong>ไม่วิ่งงาน:</strong> {summaryTruck.idleDriver}</Typography>
        <Typography width={100}><strong>ลาหยุด:</strong> {summaryTruck.numAbsent}</Typography>
      </BoxFR>
      <Box flex={1} sx={sxSumColorClass}>
        <DataGridCellExpand
          density="compact"
          hideFooter
          disableSelectionOnClick
          rows={dataTable}
          columns={columns(handleOpenDailyJobDialog)}
          getRowClassName={(params) => [-1, 0].includes(params.id) && "total"}
          experimentalFeatures={{ rowPinning: true }}
          pinnedRows={{ bottom: [sumRow] }}
        />
      </Box>

      <DailyJobDialogV2
        dialogOpen={dailyJobDialogOpen}
        setDialogOpen={setDailyJobDialogOpen}
        selectedId={selectedJobOrdId}
        onFinish={onDailyJobDialogFinish}
        lastFilter={null} />
    </BoxFC>
  );
}

export default TruckIncomeSummary;