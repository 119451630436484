import { Box, Button, Dialog, DialogContent, TextField, Typography } from "@mui/material"
import { branchApiNm } from "branch/constant/branchApiNm"
import { BoxFC, BoxFR } from "components/BoxCustom"
import PaperComponent from "components/PaperComponent"
import { DialogFooter, DialogHeader } from "components/dialog/DialogHeader"
import { GlobalStateContext } from "contexts/GlobalStateContext"
import React, { useCallback, useContext, useEffect, useState } from "react"
import { addIdForDataGrid } from "utils"
import { pcPeriodDriverColumns } from "./columns"
import DataGridCellExpand from "components/DataGridCellExpand/DataGridCellExpand"

const PCPeriodDriverDialog = ({ pcPrdId, dialogOpen, setDialogOpen, onFinish }) => {

  const { ax, msData } = useContext(GlobalStateContext)
  const [dataTable, setDataTable] = useState([])
  
  useEffect(() => {
    if (dialogOpen) {
      ax.post(branchApiNm.getPCPeriodDriver, {PCPrdId: pcPrdId}).then(value => {
        if (value.data) {
          setDataTable(addIdForDataGrid(value.data, "DrvId"))
        }
      })
    } else {
      setDataTable([])
    }
  }, [dialogOpen, ax, pcPrdId])
  
  return (
    <Dialog
      open={dialogOpen}
      PaperComponent={PaperComponent}
      aria-labelledby="draggable-dialog-title"
      fullWidth maxWidth='xl' 
      >
      <DialogHeader handleDialogClose={() => setDialogOpen(false)}
        title="สรุปรายการบัตรเงินสด"
        onDeleteClick={null}
      />
      <DialogContent>
        <BoxFC pt={2} >
          <Box height={650}>
            <DataGridCellExpand
              hideFooter
              rows={dataTable}
              columns={pcPeriodDriverColumns}
            />
          </Box>
        </BoxFC>
      </DialogContent>
      <DialogFooter okText={""}
        handleDialogClose={() => setDialogOpen(false)}
        handleDialogOk={null} />
    </Dialog>
  )
}

export default React.memo(PCPeriodDriverDialog)