import { billingNoteColNm } from "constants/billingNoteConstant";
import { invoiceColNm } from "constants/invoiceConstant";
import { jobOrderColNm } from "constants/jobOrderConstant";
import { receiveVoucherColNm } from "constants/receiveVoucherConstant";
import { shipmentColNm } from "constants/shipmentConstant";
import dayjs from "dayjs";
import { numberFormat } from "utils";
import { getPOIPlace } from "utils/getPOIPlace";

const diffMin = (tm1, tm2) => !tm1 || !tm2? null : dayjs(tm1).diff(dayjs(tm2), 'minute')

const diffDay = (tm1, tm2) =>!tm1 || !tm2? null
: dayjs(dayjs(tm1).format("YYYY-MM-DD")).diff(dayjs(dayjs(tm2).format("YYYY-MM-DD")), 'day')

const late = (levTm, arvTm, aptTm) =>{
  if(!aptTm) return null
  return diffMin(levTm, dayjs(arvTm).isAfter(dayjs(aptTm))? arvTm: aptTm)
} 
const ot = (late) =>{
  
  if(!late) return null
  // console.log("min::", min, (min-240)/60)
  return late <= 240? 0: Math.floor((late-240)/60)
}
export const columns  = [
  {
    field: 'No',
    headerName: 'ลำดับ',
    width: 70,
    align: 'center',
  },
  {
    field: 'CusSNm',
    headerName: jobOrderColNm.CusSNm,
    width: 150,
  },
  {
    field: 'JobSNm',
    headerName: jobOrderColNm.JobSNm,
    width: 150,
  },
  {
    field: 'JobNo',
    headerName: jobOrderColNm.JobNo,
    width: 150,
  },
  {
    field: 'Bkg',
    headerName: jobOrderColNm.Bkg,
    width: 150,
  },
  {
    field: 'JobOrdId',
    headerName: jobOrderColNm.JobOrdId,
    width: 100,
  },
  {
    field: 'ContNo',
    headerName: jobOrderColNm.ContNo,
    width: 120,
  },
  {
    field: 'ContSize',
    headerName: jobOrderColNm.ContSize,
    width: 80,
  },
  {
    field: 'ContTyp',
    headerName: jobOrderColNm.ContTyp,
    width: 80,
  },
  {
    field: 'ContSizeFull',
    headerName: "ขนาดตู้รวม",
    width: 80,
  },
  {
    field: 'TakePlc',
    headerName: jobOrderColNm.TakePlc,
    valueGetter: (params) => getPOIPlace(params.row, "TAKE"),
    width: 100,
  },
  {
    field: 'Loc',
    headerName: jobOrderColNm.Loc,
    valueGetter: (params) => getPOIPlace(params.row, "LOC"),
    width: 100,
  },
  {
    field: 'RtnPlc',
    headerName: jobOrderColNm.RtnPlc,
    valueGetter: (params) => getPOIPlace(params.row, "RTN"),
    width: 100,
  },
  {
    field: 'RecvDocDte',
    headerName: shipmentColNm.RecvDocDte,
    width: 150,
    type: "dateTime",
    valueGetter: (params) => params.value && dayjs(params.value),
    valueFormatter: (params) => params.value ? params.value.format("DD/MM/YYYY HH:mm") : "",
  }, 
  {
    field: 'AptTm',
    headerName: jobOrderColNm.AptTm,
    width: 150,
    type: "dateTime",
    valueGetter: (params) => params.value && dayjs(params.value),
    valueFormatter: (params) => params.value ? params.value.format("DD/MM/YYYY HH:mm") : "",
  }, 
  {
    field: 'TakeArvTm',
    headerName: jobOrderColNm.TakeArvTm,
    width: 150,
    type: "dateTime",
    valueGetter: (params) => params.value && dayjs(params.value),
    valueFormatter: (params) => params.value ? params.value.format("DD/MM/YYYY HH:mm") : "",
  }, 
  {
    field: 'TakeLevTm',
    headerName: jobOrderColNm.TakeLevTm,
    width: 150,
    type: "dateTime",
    valueGetter: (params) => params.value && dayjs(params.value),
    valueFormatter: (params) => params.value ? params.value.format("DD/MM/YYYY HH:mm") : "",
  }, 
  {
    field: 'ArvTm',
    headerName: jobOrderColNm.ArvTm,
    width: 150,
    type: "dateTime",
    valueGetter: (params) => params.value && dayjs(params.value),
    valueFormatter: (params) => params.value ? params.value.format("DD/MM/YYYY HH:mm") : "",
  }, 
  {
    field: 'LevTm',
    headerName: jobOrderColNm.LevTm,
    width: 150,
    type: "dateTime",
    valueGetter: (params) => params.value && dayjs(params.value),
    valueFormatter: (params) => params.value ? params.value.format("DD/MM/YYYY HH:mm") : "",
  }, 
  {
    field: 'RtnArvTm',
    headerName: jobOrderColNm.RtnArvTm,
    width: 150,
    type: "dateTime",
    valueGetter: (params) => params.value && dayjs(params.value),
    valueFormatter: (params) => params.value ? params.value.format("DD/MM/YYYY HH:mm") : "",
  }, 
  {
    field: 'RtnLevTm',
    headerName: jobOrderColNm.RtnLevTm,
    width: 150,
    type: "dateTime",
    valueGetter: (params) => params.value && dayjs(params.value),
    valueFormatter: (params) => params.value ? params.value.format("DD/MM/YYYY HH:mm") : "",
  }, 
  {
    field: 'FinDte',
    headerName: jobOrderColNm.FinDte,
    width: 150,
    type: "dateTime",
    valueGetter: (params) => params.value && dayjs(params.value),
    valueFormatter: (params) => params.value ? params.value.format("DD/MM/YYYY HH:mm") : "",
  },
  {
    field: 'Agnt',
    headerName: shipmentColNm.Agnt,
    width: 100,
  },
  {
    field: 'Vssl',
    headerName: shipmentColNm.Vssl,
    width: 100,
  },
  {
    field: 'Voy',
    headerName: shipmentColNm.Voy,
    width: 100,
  },
  {
    field: 'Port',
    headerName: shipmentColNm.Port,
    width: 100,
  },
  {
    field: 'ShpmTypNm',
    headerName: shipmentColNm.ShpmTypNm,
    width: 100,
  },
  {
    field: 'TlrTyp',
    headerName: jobOrderColNm.TlrTyp,
    width: 100,
  },
  {
    field: 'DrvNNm',
    headerName: "พขร(รับผิดชอบ)",
    width: 100,
  },
  {
    field: 'LicNm',
    headerName: "ทะเบียนรถ(รับผิดชอบ)",
    width: 100,
  },
  {
    field: 'TukCd',
    headerName: "เลขรถ(รับผิดชอบ)",
    width: 100,
  },
  {
    field: 'TukCdTake',
    headerName: "เลขรถ รับ",
    width: 120,
  },
  {
    field: 'LicTake',
    headerName: "ทะเบียนรถ รับ",
    width: 120,
  },
  {
    field: 'DrvTake',
    headerName: "พขร รับ",
    width: 120,
  },
  {
    field: 'TukCdLoc',
    headerName: "เลขรถ ส่ง",
    width: 120,
  },
  {
    field: 'LicLoc',
    headerName: "ทะเบียนรถ ส่ง",
    width: 120,
  },
  {
    field: 'DrvLoc',
    headerName: "พขร ส่ง",
    width: 120,
  },
  {
    field: 'TukCdRtn',
    headerName: "เลขรถ คืน",
    width: 120,
  },
  {
    field: 'LicRtn',
    headerName: "ทะเบียนรถ คืน",
    width: 120,
  },
  {
    field: 'DrvRtn',
    headerName: "พขร คืน",
    width: 120,
  },

  // {
  //   field: 'IsOnTm',
  //   headerName: "งานนับสาย",
  //   width: 70,
  //   valueFormatter: params=>params.value === 0? '0': params.value
  // },
  // {
  //   field: 'OnTmBuff',
  //   headerName: "เผื่อเวลา(นาที)",
  //   width: 100,
  //   valueFormatter: params=>params.value === 0? '0': params.value
  // },
  // {
  //   field: 'Diff',
  //   headerName: "รวมเวลาสาย(นาที)",
  //   width: 100,
  //   valueFormatter: params=>params.value === 0? '0': params.value
  // },
  // {
  //   field: 'OnTmRslt',
  //   headerName: "ตรงเวลา",
  //   width: 100,
  //   valueFormatter: params=>params.value === 0? '0': params.value
  // },
  {
    field: 'Ref1',
    headerName: jobOrderColNm.Ref1,
    width: 100,
  },
  {
    field: 'Ref2',
    headerName: jobOrderColNm.Ref2,
    width: 100,
  },
  {
    field: 'InvNo',
    headerName: invoiceColNm.InvNo,
    width: 140,
  },
  {
    field: 'InvDte',
    headerName: invoiceColNm.InvDte,
    width: 100,
    type: "dateTime",
    valueGetter: (params) => params.value  && dayjs(params.value),
    valueFormatter: (params) => params.value ? params.value.format("DD/MM/YYYY") : "",
  },
  {
    field: 'BNNo',
    headerName: billingNoteColNm.BNNo,
    width: 140,
  },
  {
    field: 'BNDte',
    headerName: billingNoteColNm.BNDte,
    width: 100,
    type: "dateTime",
    valueGetter: (params) => params.value  && dayjs(params.value),
    valueFormatter: (params) => params.value ? params.value.format("DD/MM/YYYY") : "",
  },
  {
    field: 'OwnRcptAmnt',
    headerName: invoiceColNm.OwnRcptAmnt,
    width: 110,
    align: "right",
    valueFormatter: (params) => params.value ? numberFormat(+params.value) : "0.00",
  },
  {
    field: 'CusRcptAmnt',
    headerName: invoiceColNm.CusRcptAmnt,
    width: 110,
    align: "right",
    valueFormatter: (params) => params.value ? numberFormat(+params.value) : "0.00",
  },
  {
    field: 'Total',
    headerName: invoiceColNm.Total,
    width: 110,
    align: "right",
    valueFormatter: (params) => params.value ? numberFormat(+params.value) : "0.00",
  },
  {
    field: 'Cost',
    headerName: invoiceColNm.Cost,
    width: 110,
    align: "right",
    valueFormatter: (params) => params.value ? numberFormat(+params.value) : "0.00",
  },
  {
    field: 'Exps',
    headerName: "รายได้/ค่าใช้จ่าย",
    width: 150,
    // valueGetter: (params) => params.value && JSON.stringify(params.value),
    // valueFormatter: (params) => params.value &&params.value.length > 0 && params.value
    // valueFormatter: (params) => params.value && params.value.map(exp=>`${exp.ExpNm}:${numberFormat(exp.Total)}`).join("; ")
    valueFormatter: (params) => params.value ? params.value.map(exp=>`${exp.ExpNm}:${numberFormat(exp.Total)}`).join("; ") :""
  },
  
  {
    field: 'TrnsUntPr',
    headerName: "ค่าขนส่ง(Default)",
    width: 110,
    align: "right",
    valueFormatter: (params) => params.value ? numberFormat(+params.value) : "0.00",
  },
  {
    field: 'Trns',
    headerName: "ค่าขนส่ง",
    width: 110,
    align: "right",
    valueFormatter: (params) => params.value ? numberFormat(+params.value) : "0.00",
  },
  {
    field: 'LiftF',
    headerName: "ค่ายกตู้หนัก",
    width: 110,
    align: "right",
    valueFormatter: (params) => params.value ? numberFormat(+params.value) : "0.00",
  },
  {
    field: 'LiftE',
    headerName: "ค่ายกตู้เปล่า",
    width: 110,
    align: "right",
    valueFormatter: (params) => params.value ? numberFormat(+params.value) : "0.00",
  },
  {
    field: 'PortPass',
    headerName: "ค่าผ่านท่า",
    width: 110,
    align: "right",
    valueFormatter: (params) => params.value ? numberFormat(+params.value) : "0.00",
  },
  {
    field: 'DeptPass',
    headerName: "ค่าผ่านลาน",
    width: 110,
    align: "right",
    valueFormatter: (params) => params.value ? numberFormat(+params.value) : "0.00",
  },
  {
    field: 'OthCusRcpt',
    headerName: "ใบเสร็จลูกค้าอื่นๆ",
    width: 110,
    align: "right",
    valueFormatter: (params) => params.value ? numberFormat(+params.value) : "0.00",
  },
  {
    field: 'RvNo',
    headerName: receiveVoucherColNm.Rv,
    width: 120,
  },
  {
    field: 'PayDte',
    headerName: receiveVoucherColNm.InvDte,
    width: 100,
    type: "dateTime",
    valueGetter: (params) => params.value  && dayjs(params.value),
    valueFormatter: (params) => params.value ? params.value.format("DD/MM/YYYY") : "",
  },
  {
    field: 'DiffTakeLevArvTm',
    headerName: "ออก-เข้ารับตู้",
    width: 100,
    valueFormatter: params=>params.value === null?"": `${params.value}`
  },
  {
    field: 'DiffTakeLevArvTmMin',
    headerName: "(นาที)",
    width: 60,
    valueGetter: params=> diffMin(params.row.TakeLevTm, params.row.TakeArvTm),
    valueFormatter: params=>params.value === null?"": `${params.value}`
  },
  {
    field: 'DiffTakeLevArvTmHr',
    headerName: "(ชม)",
    width: 60,
    valueGetter: params=> Math.floor(diffMin(params.row.TakeLevTm, params.row.TakeArvTm)/60),
    valueFormatter: params=>params.value === null?"": `${params.value}`
  },
  {
    field: 'DiffRtnLevArvTm',
    headerName: "ออก-เข้าคืนตู้",
    width: 100,
    valueFormatter: params=>params.value === null?"": `${params.value}`
  },
  {
    field: 'DiffRtnLevArvTmMin',
    headerName: "(นาที)",
    width: 60,
    valueGetter: params=> diffMin(params.row.RtnLevTm, params.row.RtnArvTm),
    valueFormatter: params=>params.value === null?"": `${params.value}`
  },
  {
    field: 'DiffRtnLevArvTmHr',
    headerName: "(ชม)",
    width: 60,
    valueGetter: params=> Math.floor(diffMin(params.row.RtnLevTm, params.row.RtnArvTm)/60),
    valueFormatter: params=>params.value === null?"": `${params.value}`
  },
  {
    field: 'DiffLevArvTm',
    headerName: "ออก-เข้ารง.",
    width: 100,
    valueFormatter: params=>params.value === null?"": `${params.value}`
  },
  {
    field: 'DiffLevArvTmMin',
    headerName: "(นาที)",
    width: 60,
    valueGetter: params=> diffMin(params.row.LevTm, params.row.ArvTm),
    valueFormatter: params=>params.value === null?"": `${params.value}`
  },
  {
    field: 'DiffLevArvTmHr',
    headerName: "(ชม)",
    width: 60,
    valueGetter: params=> Math.floor(diffMin(params.row.LevTm, params.row.ArvTm)/60),
    valueFormatter: params=>params.value === null?"": `${params.value}`
  },
  {
    field: 'DiffLevArvTmDay',
    headerName: "(วัน)",
    width: 60,
    valueGetter: params=> diffDay(params.row.LevTm, params.row.ArvTm),
    valueFormatter: params=>params.value === null?"": `${params.value}`
  },
  {
    field: 'Late',
    headerName: "ออก-เข้า|นัด(นาที)",
    width: 100,
    valueGetter: params=> late(params.row.LevTm, params.row.ArvTm, params.row.AptTm),
    valueFormatter: params=>params.value === null?"": `${params.value}`
  },
  {
    field: 'OverTime',
    headerName: "OT(>4ชม)",
    width: 100,
    valueGetter: params=> ot(late(params.row.LevTm, params.row.ArvTm, params.row.AptTm)),
    valueFormatter: params=>params.value === null?"": `${params.value}`
  },
  {
    field: 'DiffArvAptTm',
    headerName: "เข้า-นัดรง.",
    width: 100,
    valueFormatter: params=>params.value === null?"": `${params.value}`
  },
  {
    field: 'DiffArvAptTmMin',
    headerName: "(นาที)",
    width: 60,
    valueGetter: params=> diffMin(params.row.ArvTm, params.row.AptTm),
    valueFormatter: params=>params.value === null?"": `${params.value}`
  },
  {
    field: 'DiffFinLevTm',
    headerName: "จบ-ออกรง.",
    width: 100,
    valueFormatter: params=>params.value === null?"": `${params.value}`
  },
  {
    field: 'DiffFinLevTmMin',
    headerName: "(นาที)",
    width: 60,
    valueGetter: params=>  diffMin(params.row.FinDte, params.row.LevTm),
    valueFormatter: params=>params.value === null?"": `${params.value}`
  },
  {
    field: 'DiffFinLevTmDay',
    headerName: "(วัน)",
    width: 60,
    valueGetter: params=> diffDay(params.row.FinDte, params.row.LevTm),
    valueFormatter: params=>params.value === null?"": `${params.value}`
  },
  {
    field: 'DiffAptRecvDoc',
    headerName: "ใบแจ้งลาก-นัด",
    width: 100,
    valueFormatter: params=>params.value === null?"": `${params.value}`
  },
  {
    field: 'DiffAptRecvDocMin',
    headerName: "(นาที)",
    width: 60,
    valueGetter: params=> diffMin(params.row.AptTm, params.row.RecvDocDte),
    valueFormatter: params=>params.value === null?"": `${params.value}`
  },
  {
    field: 'DiffInvFinDte',
    headerName: "Inv-จบ(วัน)",
    width: 100,
    valueFormatter: params=>params.value === null?"": params.value === 0? '0': params.value
  },
  {
    field: 'DiffPayFinDte',
    headerName: "จ่าย-จบ(วัน)",
    width: 100,
    valueFormatter: params=>params.value === null?"": params.value === 0? '0': params.value
  },
  {
    field: 'DiffPayInvDte',
    headerName: "จ่าย-inv(วัน)",
    width: 100,
    valueFormatter: params=>params.value === null?"": params.value === 0? '0': params.value
  },
  {
    field: 'DiffPayBNDte',
    headerName: "จ่าย-BN(วัน)",
    width: 100,
    valueFormatter: params=>params.value === null?"": params.value === 0? '0': params.value
  },
  {
    field: 'DiffPayAptTm',
    headerName: "จ่าย-นัด(วัน)",
    width: 100,
    valueFormatter: params=>params.value === null?"": params.value === 0? '0': params.value
  },
  {
    field: 'DiffNowInvDte',
    headerName: "Now-Inv(วัน)",
    width: 100,
    valueFormatter: params=>params.value === null?"": params.value === 0? '0': params.value
  },
  {
    field: 'JoRmk',
    headerName: "หมายเหตุใบงาน",
    width: 200,
  },
  {
    field: 'InvRmk',
    headerName: "หมายเหตุ Inv",
    width: 140,
  },
]

export const invColumns = [
  {
    field: "InvDte",
    headerName: "Date",
    width: 100,
    type: "date",
    valueGetter: (params) => params.value  && dayjs(params.value).format("YYYY-MM-DD"),
    valueFormatter: (params) => params.value ? dayjs(params.value).format("DD/MM/YYYY") : "",
  },
  {
    field: "ContNo",
    headerName: "Container No.",
    width: 120,
  },
  {
    field: "ContSizeFull",
    headerName: "Type",
    valueGetter: (params) => params.value === "บรรทุก"? "LCL": params.value,
    width: 90,
  },
  {
    field: "Loc",
    headerName: "Delivery Place",
    flex: 1,
  },
  {
    field: "Total",
    headerName: "Price",
    width: 120,
    valueFormatter: (params) => params.value ? numberFormat(+params.value) : "",
    align: "right",
  },
  {
    field: "Vat",
    headerName: "Vat7%",
    width: 100,
    valueGetter: (params) => +params.row.Total * 0.07,
    valueFormatter: (params) => params.value ? numberFormat(+params.value) : "",
    align: "right",
  },
  {
    field: "Wht",
    headerName: "Wht.3%",
    width: 100,
    valueGetter: (params) => +params.row.Total * 0.03,
    valueFormatter: (params) => params.value ? numberFormat(+params.value) : "",
    align: "right",
  },
  {
    field: "Customs",
    headerName: "Customs",
    valueGetter: (params) => "-",
    width: 100,
    align: "right",
  },
  {
    field: "Amnt",
    headerName: "Amount",
    width: 100,
    valueGetter: (params) => +params.row.Total + (+params.row.Total * 0.07) - (+params.row.Total * 0.03),
    valueFormatter: (params) => params.value ? numberFormat(+params.value) : "",
    align: "right",
  }

]

export const bnColumns = [
  {
    field: "JobNo",
    headerName: "Job No.",
    flex: 1,
  },
  {
    field: "InvNo",
    headerName: "Invoice No.",
    flex: 1,
  },
  {
    field: "ContSizeFull",
    headerName: "Type",
    valueGetter: (params) => params.value === "บรรทุก"? "LCL": params.value,
    width: 90,
  },
  {
    field: "Qty",
    headerName: "Job No.",
    width: 70,
    align: "center",
  },
  {
    field: "Total",
    headerName: "Price",
    width: 120,
    valueFormatter: (params) => params.value ? numberFormat(+params.value) : "",
    align: "right",
  },
  {
    field: "Vat",
    headerName: "Vat7%",
    width: 100,
    valueGetter: (params) => +params.row.Total * 0.07,
    valueFormatter: (params) => params.value ? numberFormat(+params.value) : "",
    align: "right",
  },
  {
    field: "Wht",
    headerName: "Wht.3%",
    width: 100,
    valueGetter: (params) => +params.row.Total * 0.03,
    valueFormatter: (params) => params.value ? numberFormat(+params.value) : "",
    align: "right",
  },
  {
    field: "Other",
    headerName: "Other",
    valueGetter: (params) => "-",
    width: 100,
  },
  {
    field: "Amnt",
    headerName: "Amount",
    width: 100,
    valueGetter: (params) => +params.row.Total + (+params.row.Total * 0.07) - (+params.row.Total * 0.03),
    valueFormatter: (params) => params.value ? numberFormat(+params.value) : "",
    align: "right",
  }

]
