import { ArticleRounded, CheckBox, CheckBoxOutlineBlank } from "@mui/icons-material"
import { IconButton } from "@mui/material"
import dayjs from "dayjs";
import { pettyCashPeriodColNm } from "pages/Menu1/PettyCash/constant";
import { numberFormat } from "utils";


export const columns = [
  {
    field: 'ShowDetail',
    headerName: '',
    width: 40,
    align: 'center',
    renderCell: (params) => {
      return (
        <IconButton size="small" >
          <ArticleRounded color="primary" />
        </IconButton>
      )
    }
  },
  {
    field: "No",
    headerName: "No.",
    width: 50,
    align: 'center',
  },
  {
    field: "PCPrdTm",
    headerName: pettyCashPeriodColNm.PCPrdTm,
    width: 120,
    type: "dateTime",
    valueFormatter: (params) => {
      return params.value ? dayjs(params.value).format("DD/MM HH:mm") : "-";
    },
  },  
  {
    field: "PCPrdSt",
    headerName: pettyCashPeriodColNm.PCPrdSt,
    width: 120,
    type: "dateTime",
    valueFormatter: (params) => {
      return params.value ? dayjs(params.value).format("DD/MM HH:mm") : "-";
    },
  },  
  {
    field: "PCPrdEn",
    headerName: pettyCashPeriodColNm.PCPrdEn,
    width: 120,
    type: "dateTime",
    valueFormatter: (params) => {
      return params.value ? dayjs(params.value).format("DD/MM HH:mm") : "-";
    },
  },  
  {
    field: "PCJnlNm",
    headerName: pettyCashPeriodColNm.PCJnlNm,
    width: 100,
  },
  {
    field: "InitAmnt",
    headerName: pettyCashPeriodColNm.InitAmnt,
    width: 120,
    valueFormatter: (params) => params.value? (+params.value).toLocaleString():"",
    align: 'right',
  },
  {
    field: "TrnsAmnt",
    headerName: pettyCashPeriodColNm.TrnsAmnt,
    width: 120,
    valueFormatter: (params) => params.value? (+params.value).toLocaleString():"",
    align: 'right',
  },
  {
    field: "TotalAmnt",
    headerName: pettyCashPeriodColNm.TotalAmnt,
    width: 120,
    valueFormatter: (params) => params.value? (+params.value).toLocaleString():"",
    align: 'right',
  },
  {
    field: "FinAmnt",
    headerName: pettyCashPeriodColNm.FinAmnt,
    width: 130,
    valueFormatter: (params) => params.value? (+params.value).toLocaleString():"",
    align: 'right',
  },
  {
    field: "DiffAmnt",
    headerName: pettyCashPeriodColNm.DiffAmnt,
    width: 120,
    valueFormatter: (params) => params.value? (+params.value).toLocaleString():"",
    align: 'right',
    cellClassName: params=> +params.value !== 0? "has-diff": "",
  },
  {
    field: "Rmk",
    headerName: "หมายเหตุ",
    flex: 1,
    minWidth: 120,
  },
]

export const accJnlColumns = (viewMode)=> [
  {
    field: "No",
    headerName: "No.",
    width: 50,
    align: 'center',
  },
  {
    field: "Name",
    headerName: viewMode==="EMP"? "สมุดบัญชี" : "พนักงาน",
    flex: 1
  },
  {
    field: "IsAval",
    headerName: "ใช้ได้",
    width: 80,
    align: 'center',
    renderCell: (params) =>  params.value? <CheckBox color="primary"/>: <CheckBoxOutlineBlank />
  },
  ...(viewMode==="EMP"? [
    {
      field: "IsDf",
      headerName: "ค่าเริ่มต้น",
      width: 80,
      align: 'center',
      renderCell: (params) =>  params.value? <CheckBox color="primary"/>: <CheckBoxOutlineBlank />,
    }
  ]: [])
]

export const pcPeriodDriverColumns = [
  {
    field: "No",
    headerName: "No.",
    width: 50,
    align: 'center',
  },
  {
    field: "DrvNm",
    headerName: "พนักงานขับรถ",
    flex: 1,
  },
  {
    field: "BkAccNo",
    headerName: "เลขที่ บ/ช",
    width: 150,
  },
  {
    field: "LiftF",
    headerName: "ค่าชอร์",
    width: 100,
    valueFormatter: (params) => params.value? numberFormat(+params.value):"",
    align: 'right',
  },
  {
    field: "LiftE",
    headerName: "ค่ายกตู้เปล่า",
    width: 100,
    valueFormatter: (params) => params.value? numberFormat(+params.value):"",
    align: 'right',
  },
  {
    field: "Depo",
    headerName: "ค่าผ่านลาน",
    width: 100,
    valueFormatter: (params) => params.value? numberFormat(+params.value):"",
    align: 'right',
  },
  {
    field: "Gate",
    headerName: "ค่าผ่านท่า",
    width: 100,
    valueFormatter: (params) => params.value? numberFormat(+params.value):"",
    align: 'right',
  },
  {
    field: "Ot",
    headerName: "ค่าล่วงเวลา",
    width: 100,
    valueFormatter: (params) => params.value? numberFormat(+params.value):"",
    align: 'right',
  },
  {
    field: "Toll",
    headerName: "ค่าทางด่วน",
    width: 100,
    valueFormatter: (params) => params.value? numberFormat(+params.value):"",
    align: 'right',
  },
  {
    field: "EV",
    headerName: "ค่าชาร์จไฟ",
    width: 100,
    valueFormatter: (params) => params.value? numberFormat(+params.value):"",
    align: 'right',
  },
  {
    field: "Fee",
    headerName: "ค่าธรรมเนียม",
    width: 100,
    valueFormatter: (params) => params.value? numberFormat(+params.value):"",
    align: 'right',
  },
  {
    field: "Total",
    headerName: "รวม",
    width: 150,
    valueFormatter: (params) => params.value? numberFormat(+params.value):"",
    align: 'right',
  }
]