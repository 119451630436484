import { Route } from "react-router-dom/cjs/react-router-dom.min"
import JobOrderAllDetail from "./ContainerAllDetail/JobOrderAllDetail"
import TruckIncomeSummary from "./TruckIncomSummary/TruckIncomeSummary"
import JobPlaning from "./JobPlaning/JobPlaning"
import MapMT from "./MapMT/MapMT"
import JobPlaningV2 from "./JobPlaningV2/JobPlaningV2"
import InvoiceReview from "./InvoiceReview/InvoiceReview"
import PCPeriodDriver from "./PCPeriodDriver/PCPeriodDriver"


// export const branchApiNm = {
//   getJobOrderAllDetail: "/branch/getJobOrderAllDetail",
//   getTruckIncomeSummary: "/branch/getTruckIncomeSummary",
// }

export const extraPageName = {
  JobPlaning: "การวางแผนงาน",
  JobPlaningV2: "การวางแผนงาน(V2)",
  JobOrderAllDetail: "รายละเอียดงานและคชจ.",
  TruckIncomeSummary: "รายงานรายได้แยกตามรถ",
  MapMT: "แผนที่",
  InvoiceReview: "ออกใบแจ้งหนี้มนตรี",
  PCPeriodDriver: "สรุปยอดบัตรเงินสด",
}
export const extraPageMenu = [
  { page: "JobPlaning", num: "8.1", menuName: extraPageName.JobPlaning, prNum: 8 },
  { page: "JobPlaningV2", num: "8.1.1", menuName: extraPageName.JobPlaningV2, prNum: 8 },
  { page: "JobOrderAllDetail", num: "8.2", menuName: extraPageName.JobOrderAllDetail, prNum: 8,  permission: "EXE;FIN" },
  { page: "TruckIncomeSummary", num: "8.3", menuName: extraPageName.TruckIncomeSummary, prNum: 8,  permission: "EXE;FIN" },
  { page: "MapMT", num: "8.4", menuName: extraPageName.MapMT, prNum: 8 },
  { page: "PCPeriodDriver", num: "8.5", menuName: extraPageName.PCPeriodDriver, prNum: 8 },
  { page: "InvoiceReview", num: "8.6", menuName: extraPageName.InvoiceReview, prNum: 8 },
]

export const extraRoute = (path)=> [
  <Route key={"JobOrderAllDetail"} path={`${path}/JobOrderAllDetail`} ><JobOrderAllDetail /> </Route>,
  <Route key={"TruckIncomeSummary"} path={`${path}/TruckIncomeSummary`} ><TruckIncomeSummary /> </Route>,
  <Route key={"JobPlaning"} path={`${path}/JobPlaning`} ><JobPlaning /> </Route>,
  <Route key={"JobPlaningV2"} path={`${path}/JobPlaningV2`} ><JobPlaningV2 /> </Route>,
  <Route key={"MapMT"} path={`${path}/MapMt`} ><MapMT /> </Route>,
  <Route key={"InvoiceReview"} path={`${path}/InvoiceReview`} ><InvoiceReview /> </Route>,
  <Route key={"PCPeriodDriver"} path={`${path}/PCPeriodDriver`} ><PCPeriodDriver /> </Route>,
]

export const extraMainRoute = (path)=> []
