import { Box, Dialog, DialogContent, Typography } from "@mui/material"
import { BoxFC } from "components/BoxCustom"
import PaperComponent from "components/PaperComponent"
import { DialogFooter, DialogHeader } from "components/dialog/DialogHeader"
import React, { useMemo }  from "react"
import { expenseJobDetailColumns } from "./columns"
import { addSumRow, getSumColorClassName, sxSumColorClass } from "utils/highlightSumColor";
import DataGridCellExpand from "components/DataGridCellExpand/DataGridCellExpand"

const ExpensejobDetailDialog = ({  dialogOpen, setDialogOpen, dataTable, title }) => {
  
   
  const modDataTable = useMemo(()=>{
    return addSumRow({
      dataTable: dataTable, 
      sumColNms: ["Total"],
      sumColTotal: "ExpNm"
    })
  }, [dataTable])

  return (
    <Dialog
      open={dialogOpen}
      PaperComponent={PaperComponent}
      aria-labelledby="draggable-dialog-title"
      fullWidth maxWidth='lg' 
      >
      <DialogHeader handleDialogClose={() => setDialogOpen(false)}
        title={"รายการข้อมูล"}
        onDeleteClick={null}
      />
      <DialogContent>
        <BoxFC pt={2} sx={{alignItems: "center"}}>
          <Typography variant="h6" color="primary">{title}</Typography>
          <Box height={600} width={"100%"} sx={sxSumColorClass}>
            <DataGridCellExpand
              hideFooter
              rows={modDataTable}
              columns={expenseJobDetailColumns}
              getRowClassName={getSumColorClassName}
            />
          </Box>
        </BoxFC>
      </DialogContent>
      <DialogFooter okText={""}
        handleDialogClose={() => setDialogOpen(false)}
        handleDialogOk={null} />
    </Dialog>
  )
}

export default React.memo(ExpensejobDetailDialog)