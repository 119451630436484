import { Article } from "@mui/icons-material";
import { IconButton, Typography } from "@mui/material";
import { grey } from "@mui/material/colors";
import { BoxFC, BoxFR } from "components/BoxCustom";
import dayjs from "dayjs";
import { getPOIPlace } from "utils/getPOIPlace";
import BatteryColor from "../JobPlaning/BatteryColor";

export const truckComumns = (handleOpenJobOrdDialog) => [
  {
    field: 'No',
    headerName: 'ลำดับ',
    width: 70,
    align: 'center',
  },
  {
    field: 'GPSTm',
    headerName: "เวลา",
    width: 100,
    type: "dateTime",
    valueFormatter: (params) => params.value ? dayjs(params.value).format("DD/MM HH:mm") : "-",
  },
  {
    field: 'StsNm',
    headerName: 'สถานะ',
    width: 80,
    cellClassName: params => params.value
  },
  // {
  //   field: 'Spd',
  //   headerName: 'ความเร็ว',
  //   valueFormatter: (params)=>params.value?.toString(),
  //   width: 70,
  //   align: 'center',
  // },
  {
    field: 'Code',
    headerName: 'เลขรถ',
    width: 60,
  },
  {
    field: 'LicNm',
    headerName: 'ทะเบียนรถ',
    width: 80,
  },
  {
    field: 'Batt',
    headerName: 'Battery',
    width: 60,
    renderCell: (params)=> <BatteryColor percentage={params.value} width={55} />,
  },
  {
    field: 'DrvNNm',
    headerName: 'พนักงานขับรถ',
    width: 100,
  },
  {
    field: 'StnNm',
    headerName: 'สถานที่',
    width: 100,
  },
  {
    field: 'JobOrds',
    headerName: 'งาน',
    flex: 1,
    renderCell: (params) => <BoxFC p={0.5} sx={{ gap: 0 }}>
      {params.value ?
        params.value.map((item, index) => (
          <BoxFR key={index} sx={{ gap: 1 }}>
            {dayjs(item.AptTm).format("DD/MM")}
            <Typography sx={{ color: item.ContStses?.find(item => item.ContStsId === 1) ? "black" : grey[400] }}
              variant="caption">{getPOIPlace(item, "TAKE")}</Typography>-
            <Typography sx={{ color: item.ContStses?.find(item => item.ContStsId === 3) ? "black" : grey[400] }}
              variant="caption">{getPOIPlace(item, "LOC")}</Typography>-
            <Typography sx={{ color: item.ContStses?.find(item => item.ContStsId === 5) ? "black" : grey[400] }}
              variant="caption">{getPOIPlace(item, "RTN")}</Typography>
            <IconButton sx={{ p: 0 }} onClick={handleOpenJobOrdDialog(item.JobOrdId)}>
              <Article fontSize="small" color="primary" />
            </IconButton>
          </BoxFR>))
        :
        "-"
      }
    </BoxFC>
  },
]

export const historyColumns = [

  {
    field: 'No',
    headerName: 'ลำดับ',
    width: 70,
    align: 'center',
  },
  {
    field: 'TukTmSt',
    headerName: "เวลาเข้า",
    width: 100,
  },
  {
    field: 'TukTmEn',
    headerName: "เวลาออก",
    width: 100,
  },
  {
    field: 'SName',
    headerName: "สถานที่",
    flex: 1,
    minWidth: 150,
  },
]