import { Circle, GoogleMap, OverlayView, OverlayViewF, Polygon, useJsApiLoader } from "@react-google-maps/api";
import { BoxFC, BoxFR } from "components/BoxCustom";
import { GlobalStateContext } from "contexts/GlobalStateContext";
import React, { useCallback, useContext, useEffect, useMemo, useState } from "react";
import { LAEMCHABANG_SEA_PORT } from "../../../pages/Menu1/BookingManagement/GoogleMapWrapper";
import { Box, Button, CircularProgress, IconButton, Paper, Popover, TextField, Typography } from "@mui/material";
import { historyColumns, truckComumns } from "./columns";
import DataGridCellExpand from "components/DataGridCellExpand/DataGridCellExpand";
import { addIdForDataGrid } from "utils";
import { blueGrey, green, grey, red, yellow } from "@mui/material/colors";
import DailyJobDialogV2 from "../../../pages/Menu1/DailyJob/DailyJobDialogV2";
import { ArrowCircleUpRounded, ArticleRounded, ChevronLeftRounded, ChevronRightRounded, HistoryRounded, RefreshRounded } from "@mui/icons-material";
import NavigationIcon from '@mui/icons-material/Navigation';
import dayjs from "dayjs";
import { getPOIPlace } from "utils/getPOIPlace";
import ComboBox from "components/ComboBox";
import ClearButton from "components/buttons/ClearButton";
import { DesktopDatePicker, DesktopTimePicker } from "@mui/x-date-pickers-pro";
import SearchButton from "components/buttons/SearchButton";
import { coreApiNm } from "constants/serverConstant";
import CheckboxFormControl from "components/CheckboxFormControl";
import { alertError } from "components/Alert";
import { poiApiNm } from "constants/POIConstant";
import { branchApiNm } from "branch/constant/branchApiNm";

let tukTm = "00:00"
const containerStyle = {
  width: '100%',
  height: '100%'
};

const stsColor = {
  '33': grey[500],
  '31': yellow[600],
  '30': green[500]
}
const initFilterData = {
  CusId: null,
  JobId: null,
  Bkg: null,
  TagIds: [],
  TukId: null,
}

const initHistoryFilterData = {
  TukId: null,
  TukDte: dayjs(),
  TukTmSt: dayjs("1970-01-01 00:00"),
  TukTmEn: dayjs("1970-01-01 23:59:59.997"),
}

const initLatLngData = {
  lat: null,
  lng: null,
  rad: null,
  points: null
}
let allPOIs = []

const drawBattBox = (batt) => {
  const borderColor = batt > 50 ? green[600] : batt > 25 ? yellow[800] : red[600]
  const color = batt > 50 ? green[100] : batt > 25 ? yellow[300] : red[100]
  const battBoxNum = Math.ceil(batt / 10)
  const battBoxes = []
  for (let i = 0; i < battBoxNum; i++) {
    battBoxes.push(
      <Box key={i} width={7} height={6} sx={{ bgcolor: color, border: `1px solid ${borderColor}` }} />
    )
  }
  return (
    <BoxFR width={70} height={6} sx={{gap: 0, justifyContent:"flex-start", border: `1px solid ${borderColor}` }}>
      {battBoxes}
    </BoxFR>
  )
}
const TruckItem = ({ data, handleClick }) => {
  const location = useMemo(() => {
    const jobOrds = data.JobOrds
    if (!jobOrds || jobOrds.length === 0) return "-"

    const found = jobOrds.find(jobOrd => dayjs(jobOrd.AptTm).format("YYYY-MM-DD") === dayjs().format("YYYY-MM-DD"))
    const jobOrd = found || jobOrds[0]
    const takePlc = getPOIPlace(jobOrd, "TAKE")?.substring(0, 3)
    const loc = getPOIPlace(jobOrd, "LOC")
    const rtnPlc = getPOIPlace(jobOrd, "RTN")?.substring(0, 3)
    return `${takePlc}${loc === "-" ? "" : `-${jobOrd.JobAbbr}`}${rtnPlc ? `-${rtnPlc}` : ""}`
  }, [data.JobOrds])


  return (
    <BoxFC sx={{ gap: 0 }} onClick={handleClick(data.id)} position="relative"
      width={80} height={55} alignItems="center" p={0.5}
      bgcolor="white" border={`2px solid ${stsColor[data.StsCd]}`} borderRadius={2} >

      {data.StsCd !== '33' &&
        <Box bgcolor={stsColor[data.StsCd]} width={20} height={20}
          position="absolute" top={-15} left={29}
          borderRadius={3} pl={0.3} pt={0.1} sx={{ transform: `rotate(${data.Head}deg)` }}>
          <NavigationIcon sx={{ color: "white", fontSize: 17 }} />
        </Box>
      }
      <Typography variant="body" fontWeight="bold" fontSize="small">{data.Code || "-"}</Typography>

      {drawBattBox(data.Batt)}
      <Typography mt={0.5} variant="body" >{location}</Typography>
    </BoxFC>
  )
}

let selectedJobOrdId = null
const MapMT = () => {
  const [map, setMap] = useState(null)
  const { ax, msData } = useContext(GlobalStateContext)
  const [dialogDailyJobOpen, setDialogDailyJobOpen] = useState(false)
  const [selectionModel, setSelectionModel] = useState([])
  const [isGettingData, setIsGettingData] = useState(false)
  const [truckDataTable, setTruckDataTable] = useState([])
  const [filterData, setFilterData] = useState({ ...initFilterData })
  const [expandTable, setExpandTable] = useState(true)
  const [displayMode, setDisplayMode] = useState('RT') // RT=Realtime, HT=History
  const [historyFilter, setHistoryFilter] = useState({ ...initHistoryFilterData })
  const [historyData, setHistoryData] = useState([])
  const [anchorEl, setAnchorEl] = useState(null)
  const [showHistoryTm, setShowHistoryTm] = useState(false)
  const [latLngData, setLatLngData] = useState({ ...initLatLngData })

  const popOverOpen = Boolean(anchorEl);

  const historyTable = useMemo(() => {
    const results = []
    let lastObj = {
      No: 1,
      TukTmSt: null,
      TukTmEn: null,
      POIId: null,
      SName: "",
    }
    for (const row of historyData) {
      if (row.POIId) {
        if (lastObj.POIId !== row.POIId) {
          lastObj = {
            id: row.TukLocId,
            No: lastObj.No + 1,
            TukTmSt: dayjs(row.TukTime).format("DD/MM HH:mm"),
            TukTmEn: null,
            POIId: row.POIId,
            SName: row.SName,
          }
          results.push(lastObj)
        } else {
          lastObj.TukTmEn = dayjs(row.TukTime).format("DD/MM HH:mm")
        }
      }
    }
    return results
  }, [historyData])


  const handleClosePopOver = () => {
    setAnchorEl(null)
  }



  const datePickerProp = useMemo(() => ({
    label: "วันที่",
    inputFormat: "DD/MM/YYYY",
    mask: "__/__/____",
    value: historyFilter.TukDte,
    onChange: (newValue) => { setHistoryFilter(o => ({ ...o, TukDte: newValue })) },
    renderInput: (params) => <TextField size="small"  {...params} sx={{ width: 150 }} />,
  }), [historyFilter])

  const timePickerProp = useCallback((name) => ({
    ampm: false,
    label: name === "TukTmSt" ? "เวลาเริ่มต้น" : "เวลาสิ้นสุด",
    value: historyFilter[name],
    onChange: (newValue) => { setHistoryFilter(o => ({ ...o, [name]: newValue })) },
    renderInput: (params) => <TextField size="small"  {...params} sx={{ width: 110 }} />,
  }), [historyFilter])

  const filteredTruckDataTable = useMemo(() => {
    let filtered = truckDataTable
    console.log("filterData::", filterData)
    if (filterData.Bkg) {
      filtered = filtered.filter(truck => truck.JobOrds && truck.JobOrds.find(jobOrd => jobOrd.Bkg === filterData.Bkg))
    }
    if (filterData.CusId) {
      filtered = filtered.filter(truck => truck.JobOrds && truck.JobOrds.find(jobOrd => jobOrd.CusId === filterData.CusId))
    }
    if (filterData.JobId) {
      filtered = filtered.filter(truck => truck.JobOrds && truck.JobOrds.find(jobOrd => jobOrd.JobId === filterData.JobId))
    }
    if (filterData.DrvId) {
      filtered = filtered.filter(truck => truck.DrvIds.includes(filterData.DrvId))
    }
    if (filterData.TukId) {
      filtered = filtered.filter(truck => truck.TukId === filterData.TukId)
    }
    return filtered = addIdForDataGrid(filtered, 'TukId')

  }, [truckDataTable, filterData])

  const jobCombo = useMemo(() =>
    filterData.CusId ? msData.jobCombo.filter(job => job.refId === filterData.CusId) : msData.jobCombo,
    [filterData.CusId, msData.jobCombo]
  )

  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: "AIzaSyDgvxihvJPXifWy6F1T3lY5uF2vjctF4XU"
  })

  const initMapCenter = useMemo(() => {
    const lat = +msData.serverData.CompanyData.Lat
    const lng = +msData.serverData.CompanyData.Lng
    if (lat && lng) {
      return { lat, lng }
    } else {
      return LAEMCHABANG_SEA_PORT
    }
  }, [msData.serverData.CompanyData])

  const getData = useCallback(() => {
    setIsGettingData(true)
    ax.post(branchApiNm.getJobOrderTruckCoordinate, {}, false).then(value => {
      setIsGettingData(false)
      if (value.data) {
        for (const row of value.data) {
          row.JobOrds = row.JobOrds?.filter(item => item.ContStses.find(sts => [1, 3, 5].includes(sts.ContStsId)))
          row.DrvIds = row.DrvIds?.split(",")?.map(id => +id) || []
        }
        setTruckDataTable(addIdForDataGrid(value.data, 'TukId'))
      }
    })
  }, [ax])
  const onLoad = useCallback(function callback(map) {
    setMap(map)
  }, [])

  const onUnmount = useCallback(function callback(map) {
    setMap(null)
  }, [])

  const handleOpenJobOrdDialog = useCallback((jobOrdId) => () => {
    selectedJobOrdId = jobOrdId
    setDialogDailyJobOpen(true)
  }, [])

  const handleSelectionModelChange = useCallback((ids) => {
    setSelectionModel(ids)
    console.log("ids::", ids)
    console.log("filteredTruckDataTable::", filteredTruckDataTable)
    const selectedTruck = filteredTruckDataTable.find(truck => truck.id === ids[0])
    console.log("selectedTruck::", selectedTruck)
    if (selectedTruck.Lat && selectedTruck.Lng) {
      map.panTo({ lat: selectedTruck.Lat, lng: selectedTruck.Lng })
    }
  }, [map, filteredTruckDataTable])

  const handleTruckLabelClick = useCallback((id) => () => {
    setSelectionModel(id)
  }, [])

  const onDialogDailyJobFinish = useCallback(() => {
    getData()
  }, [getData])

  const handleSearchHistory = useCallback(() => {
    if (!historyFilter.TukId) {
      alertError("กรุณาเลือกรถก่อน")
      return
    }
    const postData = {
      TukId: historyFilter.TukId,
      TukTmSt: `${historyFilter.TukDte.format("YYYY-MM-DD")} ${historyFilter.TukTmSt.format("HH:mm:00")}`,
      TukTmEn: `${historyFilter.TukDte.format("YYYY-MM-DD")} ${historyFilter.TukTmEn.format("HH:mm:59.997")}`,
    }
    ax.post(coreApiNm.getTruckLocation, postData).then(value => {
      if (value.data) {
        setLatLngData({ ...initLatLngData })
        setHistoryData(value.data)
      }
    })
  }, [ax, historyFilter])

  const handleHistoryRowClick = useCallback((params) => {
    console.log("params::", params)
    const poi = allPOIs.find(poi => poi.POIId === params.row.POIId)
    if (poi) {
      if (poi.PolyStr && !poi.points) {
        const points = poi.PolyStr.replace("POLYGON((", "").replace("))", "").split(",").map(point => {
          const latLng = point.trim().split(" ")
          return { lat: +latLng[0], lng: +latLng[1] }
        })
        poi.points = points
      }
      let mapCenter = null

      if (poi.lat && poi.lng) {
        mapCenter = { lat: poi.lat, lng: poi.lng }
      } else if (poi.points && poi.points.length > 0) {
        const points = poi.points
        // this code is generate from 
        const lat = points.reduce((acc, point) => acc + point.lat, 0) / points.length
        const lng = points.reduce((acc, point) => acc + point.lng, 0) / points.length
        mapCenter = { lat, lng }
      }
      map.panTo(mapCenter)

      setLatLngData({
        lat: poi.Lat,
        lng: poi.Lng,
        rad: poi.Rad,
        points: poi.points
      })
    }
  }, [map])
  useEffect(() => {
    const interval = setInterval(() => {
      getData()
    }, 60000);
    getData()
    ax.post(poiApiNm.getPOI).then(value => {
      allPOIs = value.data
    })
    return () => clearInterval(interval);
  }, [ax, getData])
  return (
    <BoxFR width="100%" height="100%" sx={{ alignItems: "stretch" }}>

      <BoxFC width={expandTable ? 950 : 350} overflow="auto" position="relative">
        <BoxFR>
          <Typography variant="h6">{displayMode === "RT" ? "ข้อมูลรถ" : "ข้อมูลย้อนหลัง"}</Typography>
          {
            displayMode === "RT" ?
              <IconButton onClick={getData} sx={{ p: 0 }}>
                <RefreshRounded color="primary" />
              </IconButton> : null
          }
          {/* <Button variant="contained" color="primary" size="small" sx={{minWidth:0}} >
        </Button> */}
          {isGettingData && <CircularProgress size={17} />}
          <Box flexGrow={1} />
          {
            displayMode === "RT" ?
              <Button variant="contained" onClick={() => setDisplayMode("HT")}><HistoryRounded sx={{ mr: 1 }} />ข้อมูลย้อนหลัง</Button> :
              <Button variant="contained" onClick={() => setDisplayMode("RT")}><ArticleRounded sx={{ mr: 1 }} />ข้อมูลรถ</Button>
          }
        </BoxFR>
        {displayMode === "RT" ?

          <BoxFC flex={1}>
            <BoxFR>
              <Typography >Fiter:</Typography>
              <ComboBox
                sx={{ width: 120 }}
                options={msData.truckCombo}
                label="เลขรถ"
                selectedId={filterData.TukId}
                setSelectedId={(id) => setFilterData(o => ({ ...o, TukId: id }))} />
              {expandTable ?
                <BoxFR>
                  <ComboBox
                    sx={{ width: 120 }}
                    options={msData.driverOnlyCombo}
                    label="พนักงานขับรถ"
                    selectedId={filterData.DrvId}
                    setSelectedId={(id) => setFilterData(o => ({ ...o, DrvId: id }))} />
                  <TextField size="small" label="Booking" sx={{ width: 120 }}
                    value={filterData.Bkg || ""}
                    onChange={(e) => setFilterData(o => ({ ...o, Bkg: e.target.value || null }))} />
                  <ComboBox
                    sx={{ width: 150 }}
                    options={msData.cusCombo}
                    label="ลูกค้า"
                    selectedId={filterData.CusId}
                    setSelectedId={(id) => setFilterData(o => ({ ...o, CusId: id }))} />
                  <ComboBox
                    sx={{ width: 150 }}
                    options={jobCombo}
                    label="งาน"
                    selectedId={filterData.JobId}
                    setSelectedId={(id) => setFilterData(o => ({ ...o, JobId: id }))} />
                </BoxFR> : null
              }
              {
                expandTable ?
                  <ClearButton onClick={() => setFilterData({ ...initFilterData })} /> : null
              }
            </BoxFR>
            <Box minWidth={900} width='100%' flexGrow={1}>
              <DataGridCellExpand
                disableSelectionOnClick={false}
                hideFooter
                rows={filteredTruckDataTable}
                columns={truckComumns(handleOpenJobOrdDialog)}
                getRowHeight={() => 'auto'}
                selectionModel={selectionModel}
                onSelectionModelChange={handleSelectionModelChange} />
            </Box>

          </BoxFC>
          :
          <BoxFC flex={1}>
            <BoxFR>
              <ComboBox sx={{ width: 150 }}
                options={msData.truckCombo}
                label="รถ"
                selectedId={historyFilter.TukId}
                setSelectedId={(id) => setHistoryFilter(o => ({ ...o, TukId: id }))} />
              <DesktopDatePicker {...datePickerProp} />
              <DesktopTimePicker {...timePickerProp("TukTmSt")} />
              <DesktopTimePicker {...timePickerProp("TukTmEn")} />
              <SearchButton onClick={handleSearchHistory} />
              <Box flex={1} />
              <CheckboxFormControl label="แสดงเวลา" checked={showHistoryTm} onChange={e => setShowHistoryTm(e.target.checked)} />
            </BoxFR>
            <Typography variant="h6">สรุปสถานที่</Typography>
            <Box minWidth={900} width='100%' flexGrow={1}>
              <DataGridCellExpand
                hideFooter
                rows={historyTable}
                columns={historyColumns}
                getRowHeight={() => 'auto'}
                onRowClick={handleHistoryRowClick} />
            </Box>
          </BoxFC>
        }
      </BoxFC>
      <BoxFC minWidth={700} flex={1} position="relative">
        {isLoaded ?

          <GoogleMap
            mapContainerStyle={containerStyle}
            center={initMapCenter}
            zoom={14}
            onLoad={onLoad}
            onUnmount={onUnmount}
          >
            {displayMode === "RT" ?
              filteredTruckDataTable.map((truck) => {
                return (
                  <OverlayViewF key={truck.id}
                    position={{ lat: truck.Lat, lng: truck.Lng }}
                    mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}
                    getPixelPositionOffset={(width, height) => ({ x: -(width / 2), y: 0 })}>
                    <TruckItem data={truck} handleClick={handleTruckLabelClick} />
                  </OverlayViewF>
                )
              })
              :
              historyData.map((tukLoc) => (
                <OverlayViewF key={tukLoc.TukLocId}
                  position={{ lat: tukLoc.Lat, lng: tukLoc.Lng }}
                  mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}
                  getPixelPositionOffset={(width, height) => ({ x: -(width / 2), y: -(height / 2) })}>
                  <Box position="relative">
                    {showHistoryTm &&
                      <Typography variant="caption" position="absolute" top={-18} left={-6}>{dayjs(tukLoc.TukTime).format("HH:mm")}</Typography>
                    }
                    <Box width={24} height={24} borderRadius={2.5}
                      sx={{ transform: `rotate(${tukLoc.Head}deg)` }}
                      onMouseOver={e => { console.log("mouseOver") }}
                      onClick={e => {
                        tukTm = dayjs(tukLoc.TukTime).format("HH:mm")
                        setAnchorEl(e.currentTarget)
                      }}
                      bgcolor={tukLoc.StsCd === 30 ? green[500] : tukLoc.StsCd === 31 ? yellow[700] : grey[500]}>
                      <ArrowCircleUpRounded sx={{ color: "white" }} />
                    </Box>

                  </Box>
                  {/* <ArrowCircleRightRounded 
                  sx={{color: loc.StsCd===30?green[500]: loc.StsCd===31?yellow[500]: grey[500]}} 
                  onMouseOver={e=>{console.log("mouseOver")}}/> */}
                </OverlayViewF>
              ))
            }
            {
              latLngData.lat && latLngData.lng && latLngData.rad &&
              <Circle center={latLngData} radius={+latLngData.rad}
                options={{ strokeColor: "#ff0000", fillColor: "#ff0000", fillOpacity: 0.3, strokeWeight: 2 }} />
            }
            {
              latLngData.points &&
              <Polygon
                path={latLngData.points}
                options={{ strokeColor: "#ff0000", fillColor: "#ff0000", fillOpacity: 0.3, strokeWeight: 2 }}
              />
            }
          </GoogleMap> : <Box>Loading Map...</Box>
        }
        <Box position="absolute" top="50%" left={0} bgcolor={blueGrey[50]} borderRadius="0 8px 8px 0"
          borderTop={1} borderRight={1} borderBottom={1}
          borderTopColor="silver" borderRightColor="silver" borderBottomColor="silver">
          <IconButton sx={{ px: 0 }} onClick={() => setExpandTable(o => !o)}>
            {expandTable ? <ChevronLeftRounded /> : <ChevronRightRounded />}
          </IconButton>
        </Box>
        <Popover
          open={popOverOpen}
          anchorEl={anchorEl}
          onClose={handleClosePopOver}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
          transformOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
          }}
        >
          <Paper sx={{ px: 1 }}>
            <Typography variant="caption" >{tukTm}</Typography>
          </Paper>
        </Popover>

      </BoxFC>
      <DailyJobDialogV2
        dialogOpen={dialogDailyJobOpen}
        setDialogOpen={setDialogDailyJobOpen}
        selectedId={selectedJobOrdId}
        onFinish={onDialogDailyJobFinish} />
    </BoxFR>
  )
}

export default MapMT