import { AutorenewRounded } from "@mui/icons-material";
import { Box, Button } from "@mui/material";
import { branchApiNm } from "branch/constant/branchApiNm";
import { BoxFC, BoxFR } from "components/BoxCustom";
import ComboBox from "components/ComboBox";
import DateTimeSelect from "components/DateTimeSelect";
import ClearButton from "components/buttons/ClearButton";
import SearchButton from "components/buttons/SearchButton";
import { GlobalStateContext } from "contexts/GlobalStateContext";
import dayjs from "dayjs";
import { invoiceReviewColumns } from "./columns";
import React, { useCallback, useContext, useEffect, useMemo, useState } from "react";
import DataGridCellExpand from "components/DataGridCellExpand/DataGridCellExpand";
import { addIdForDataGrid } from "utils";
import ExpenseJobDetailDialog from "./ExpenseJobDetailDialog";
import InvoiceDetailDialog from "./InvoiceDetailDialog";

const clearFilterData = {
  InvDteSt: null,
  InvDteEn: null,
  CusId: null,
}
const initFilterData = {
  ...clearFilterData,
  InvDteSt: dayjs().startOf("day").format("YYYY-MM-DD"),
  InvDteEn: dayjs().endOf("day").format("YYYY-MM-DD"),
}

let xjoDetailTable = []
let xjoDetailTitle = ""

let selectedInvId = null

const InvoiceReview = () => {

  const [filterData, setFilterData] = useState({ ...initFilterData })
  const [dataTable, setDataTable] = useState([])
  const [dialogExpenseJobDetailOpen, setDialogExpenseJobDetailOpen] = useState(false)
  const [dialogInvoiceDetailOpen, setDialogInvoiceDetailOpen] = useState(false)

  const { ax, msData } = useContext(GlobalStateContext)

  const handleDetailClick = useCallback((row, field) =>()=>{
    const postData = { 
      InvId: row.InvId, 
      JobOrdId: null,
      Field: field
    }
    ax.post(branchApiNm.getExpenseJobOrderDetail, postData).then(value => {
      if (value.data) {

        xjoDetailTable = addIdForDataGrid(value.data, "ExpJobOrdId")
        if(field === "OthOwnRcptAmnt"){
          xjoDetailTitle = `รายละเอียด ใบเสร็จบริษัทอื่นๆ ${row.InvNo}`
        } else if(field === "OthCusRcptAmnt"){
          xjoDetailTitle = `รายละเอียด ใบเสร็จลูกค้าอื่นๆ  ${row.InvNo}`
        }
        setDialogExpenseJobDetailOpen(true)
      }
    })
  }, [ax])

  const columns = useMemo(()=>invoiceReviewColumns({handleDetailClick}), [handleDetailClick])

  const getData = useCallback((filter) => {
    ax.post(branchApiNm.getInvoiceReview, filter).then(value => {
      if (value.data) {
        setDataTable(addIdForDataGrid(value.data, "InvId"))
      }
    })
  }, [ax])

  const handleRowDoubleClick = useCallback(params =>{
    selectedInvId = params.row.InvId
    setDialogInvoiceDetailOpen(true)
  }, [])
  useEffect(() => {
    getData({ ...initFilterData })
  }, [getData])
  return (
    <BoxFC height="100%">
      <BoxFR>
        <Button variant="contained" ><AutorenewRounded sx={{ mr: 1 }} /> เปลี่ยนเลขที่ใบแจ้งหนี้</Button>
        <Box flex={1} />
        <DateTimeSelect
          sx={{ width: 180 }}
          label="วันที่แจ้งหนี้"
          start={filterData.InvDteSt}
          end={filterData.InvDteEn}
          setSelectDate={(st, en) => { setFilterData(o => ({ ...o, InvDteSt: st, InvDteEn: en })) }}
          getData={() => { getData(filterData) }}
        />
        <ComboBox sx={{ width: 150 }} options={msData.cusCombo} label="ลูกค้า"
          selectedId={filterData.CusId}
          setSelectedId={(id) => setFilterData(o => ({ ...o, CusId: id }))}
        />
        <SearchButton onClick={() => getData(filterData)} />
        <ClearButton onClick={() => setFilterData({ ...clearFilterData })} />
      </BoxFR>
      <Box flex={1} >
        <DataGridCellExpand
          hideFooter
          rows={dataTable}
          columns={columns}
          onRowDoubleClick={handleRowDoubleClick}
        />
      </Box>
      <ExpenseJobDetailDialog
        dialogOpen={dialogExpenseJobDetailOpen}
        setDialogOpen={setDialogExpenseJobDetailOpen}
        dataTable={xjoDetailTable}
        title={xjoDetailTitle} />
      <InvoiceDetailDialog 
        dialogOpen={dialogInvoiceDetailOpen}
        setDialogOpen={setDialogInvoiceDetailOpen}
        invId={selectedInvId} />
    </BoxFC>
  );
}

export default InvoiceReview;